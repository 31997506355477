import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
  setUserSettingsOneClickStakes,
  setUserSettingsMobileStakesExchangeGame,
  setUserSettingsMobileStakes,
  setNotificationSettings,
  setUserSettingsStakes
} from './user.slice';
import { USER_DATA } from '../../config/app.config';
import { Socket } from '../../services/socket';
import NewHttpAuth from '../../services/NewHttpAuth';

// export const requestChangePassword = (memberCode, data) => async (dispatch) => {
export const requestChangePassword = (data) => async (dispatch) => {
  // console.log("MemberCode: ", memberCode);

  console.log("Data bolte: ", data)

  Socket.emit("password-changed", data);
  

  const PasswordChangeSuccess = async(...args) => {

    console.log("pass Success Args: ", args[0]);

    return args[0];
  }

  const PasswordChangeError = async(...args) => {

    console.log("pass Fail Args: ", args[0]);

    return args[0];
  }
  

  Socket.on("password-changed-success", PasswordChangeSuccess);
  Socket.on("password-changed-error", PasswordChangeError);


  // try {
  //   const response = await NewHttpAuth.put(`account/settings/password`, data)
  //   console.log("Response: ", response);
  //   if (response.data.success) {
  //     // pass changed
  //   }
  //   return response
  // } catch (e) {
  //   console.log("e: ", e);
  //   const errorMsg = getErrorMessage(e)
  //   throw errorMsg
  // }

}

export const requestSaveOneClickSettings = (memberCode, settings, withApi = false) => async (dispatch, getState) => {
  try {
    if (withApi) {
      const response = await http.post(`account/${memberCode}/settings/one-click`, settings)
      if (response.data.success) {
        dispatch(setUserSettingsOneClickStakes(settings));
        localStorage.setItem(USER_DATA, JSON.stringify(getState().user));
      }
      return response
    } else {

      dispatch(setUserSettingsOneClickStakes(settings));
      localStorage.setItem(USER_DATA, JSON.stringify(getState().user));
    }
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestSaveMobileStakesExchangeGameSettings = (memberCode, settings) => async (dispatch, getState) => {
  try {
    const response = await http.post(`account/${memberCode}/settings/mobile-stakes-exchange-game`, { settings })
    if (response.data.success) {
      dispatch(setUserSettingsMobileStakesExchangeGame(settings));
      localStorage.setItem(USER_DATA, JSON.stringify(getState().user));
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestSaveMobileStakesSettings = (memberCode, settings) => async (dispatch, getState) => {
  try {
    const response = await http.post(`account/${memberCode}/settings/mobile-stakes`, { settings })
    if (response.data.success) {
      dispatch(setUserSettingsMobileStakes(settings));
      localStorage.setItem(USER_DATA, JSON.stringify(getState().user));
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestSaveStakesSettings = (memberCode, settings) => async (dispatch, getState) => {
  try {
    const response = await http.post(`account/${memberCode}/settings/stakes`, { settings })
    if (response.data.success) {
      dispatch(setUserSettingsStakes(settings));
      localStorage.setItem(USER_DATA, JSON.stringify(getState().user));
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const setNotifications = (settings) => async (dispatch, getState) => {
  try {
    dispatch(setNotificationSettings(settings));
    localStorage.setItem(USER_DATA, JSON.stringify(getState().user));
    return true
  } catch (e) {
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
