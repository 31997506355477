import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { useDispatch } from "react-redux";
import { IonContent, IonPage, IonHeader, useIonViewWillEnter } from "@ionic/react";
import { setDetailedDepositTransaction } from "../../store/detailedDepositTransaction/detailedDepositTransaction.slice";
import { connectTransactions } from "./Transactions.connect";
import { BASE_DEV_URL } from "../../config/api.config";
import PopUp from "../DetailedDepositTransaction/PopUp";
import FooterDesktop from "../../components/FooterDesktop/FooterDesktop";
import getErrorMessage from "../../helpers/getErrorMessage.helper";
import {CONFIG} from '../../config/api.config';

import Toolbar from "../../components/Toolbar/Toolbar";
import PayTM from "../../assets/images/payTM.svg";
import UPI from "../../assets/images/UPI.svg";
import IMPS from "../../assets/images/IMPS.svg";
import KHELOCARD from "../../assets/images/KHELOCARD.svg";
import PayU from "../../assets/images/PAYU.svg";
import Refresh from "../../assets/images/refresh.svg";
import barcode from "../../assets/images/qr-code.jpg";
import "./Transactions.scss";

import IconSuccessful from "../../assets/images/transactions/IconSuccessful.svg";
import IconFailed from "../../assets/images/transactions/IconFailed.svg";
import IconWaiting from "../../assets/images/transactions/IconWaiting.svg";
import IconArrow from "../../assets/images/transactions/IconArrow.svg";
import noDepositsImage from "../../assets/images/transactions/noDepositsImage.jpg";
import noWithdrawalsImage from "../../assets/images/transactions/noWithdrawalsImage.jpg";
import { getCurrencyFormat } from "../../helpers/getCurrencyFormat.helper";


const Transactions = ({ user, actions, elementsParams, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth;

  const [transactionsResponse, settransactionsResponse] = useState();
  const [oneTransactionResponse, setOneTransactionResponse] = useState([]);
  const [isDeposit, setIsDeposit] = useState(true);

  useEffect(() => {
    if(!user?.verifytoken) {
      history.push("/default-page");
    }
  }, [user])

  const toggleTab = () => {
    setIsDeposit((prevCheck) => !prevCheck);
  };

  const token = localStorage.getItem("id_token");

  const updateTransactionsRequest = async () => {
    if(!user?.verifytoken) return;

    return await axios.post(BASE_DEV_URL + `transactions`, {from: '2022-08-21', to: '2024-08-28'}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        if(response.status == 200 && response?.data?.success) {
          settransactionsResponse(response?.data?.data);
        }


        // try {
        //   const memberCode = JSON.parse(localStorage.getItem("user_data")).memberCode;
        //   response.data.result.filter(a => a.status == "Complete").slice(0, 5).forEach(result => {
        //     window.srtmCommands.push({
        //       event: "track.betting.deposit",
        //       payload: {
        //         action: "created",
        //         userId: memberCode,
        //         transactionId: result.requestId,
        //         amount: result.amount,
        //         currency: "INR"
        //       }
        //     });
        //   });

        // } catch (e) {


        // }

      })
      .catch((e) => {
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      });
  };

  const updateOneTransactionRequest = async (id) => {
    return await axios
      .get(BASE_DEV_URL + `/payments/userid/txns?requestId=${id}`, {
        headers: {
          Authorization: `${token}`
        }
      })
      .then((response) => {
        setOneTransactionResponse(response.data.result[0]);

        updateTransaction(
          response.data.result[0].requestId,
          response.data.result[0]
        );
      })
      .catch((e) => {
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      });
  };
  const updateOneTransactionDetailsRequest = async (id) => {
    return await axios
      .get(BASE_DEV_URL + `/payments/userid/txns?requestId=${id}`, {
        headers: {
          Authorization: `${token}`
        }
      })
      .then((response) => {
        setOneTransactionResponse(response.data.result[0]);
      })
      .catch((e) => {
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      });
  };

  const updateTransaction = (id, oneTransaction) => {
    let temp_state = [...transactionsResponse];
    const index = temp_state.findIndex((elem) => elem.requestId === id);
    temp_state[+index] = oneTransaction;
    settransactionsResponse(temp_state);
  };

  useEffect(() => {
    updateTransactionsRequest();
  }, [isDeposit]);

  useIonViewWillEnter(() => {
    updateTransactionsRequest();
  });

  const [openPopUp, setOpenPopUp] = useState(false);
  const [editPoUP, seteditPoUP] = useState(false);

  const pageRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  // console.log("isDeposit", isDeposit);
  // console.log("transactionsResponse", transactionsResponse);

  return width < 1024 ? (
    <>
      <IonPage>
        <IonHeader>
          <Toolbar />
        </IonHeader>
        {/* <div className="transactions-menu">
          <div
            onClick={() => toggleTab()}
            className={`deposite ${isDeposit && 'activeTr'} `}
          >
            DEPOSIT
          </div>
          <div
            onClick={() => toggleTab()}
            className={`withdrawal ${!isDeposit && 'activeTr'}`}
          >
            WITHDRAWAL
          </div>
        </div> */}
        <IonContent className="ChangePassword" fullscreen>
          <div className="transactions-container">
            <div className="transactions-container__buttons">
              <div className={`transactions-container__buttons-item ${isDeposit && "checkedItem"}`}
                   onClick={() => toggleTab()}>
                Deposit
              </div>
              <div className={`transactions-container__buttons-item ${!isDeposit && "checkedItem"}`}
                   onClick={() => toggleTab()}>
                Withdrawal
              </div>
            </div>
          </div>
          {isDeposit && (
            <div className="transactions-container-information">
              {
                !transactionsResponse &&
                <div className={`transactions-container-information__no-deposits`}>
                  <img loading={"lazy"} src={noDepositsImage} alt="" />
                  <button className={`transactions-container-information__add-deposit-btn`}
                          onClick={() => {
                            history.push("/deposit");
                          }}
                  >
                    <span>+ Add Deposit</span>
                  </button>
                </div>
              }
              {transactionsResponse &&
              transactionsResponse.filter((item) => item.type === "Deposit").length === 0
                ? (<div>No Deposit transaction yet </div>)
                : null}
              {transactionsResponse &&
                transactionsResponse
                  ?.filter((item) => item.type === "Deposit")
                  ?.sort(function(a, b) {
                    return b.createdAt - a.createdAt;
                  })
                  ?.map((item) => (
                    <div className="transactions-container-information-item" onClick={() => {
                      dispatch(setDetailedDepositTransaction(item));
                      history.push("/detailedDepositTransaction");
                    }}
                         key={item?._id}>

                      <div className="transactionsInformation">
                        <div
                          className={`transactionsInformation-img status successfull ${item?.status === "Decline" || item?.status === "Cancelled"
                            ? "statusFailed"
                            : (item.status === "Pending" || item.status === "Initiated")
                              ? "statusPending"
                              : "statusSuccessful"}`}>
                          <img loading={"lazy"} src={item?.status === "Decline" || item?.status === "Cancelled"
                            ? IconFailed
                            : (item.status === "Pending" || item.status === "Initiated")
                              ? IconWaiting
                              : IconSuccessful} alt="" />
                        </div>
                        <div className="transactionsInformation-data">
                          <div className="transactionsInformation-data__id">#{item?._id}</div>
                          <div className="transactionsInformation-data__dateTime">
                            {new Date(item?.createdAt).toLocaleString("en", {
                              month: "long",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric"
                            }).replace("at", " ")}
                          </div>
                        </div>
                        <div className="transactionsInformation-amount">
                          ₹ {getCurrencyFormat(item?.amount)}
                        </div>
                        <div className="transactionsInformation-arrow">
                          <img loading={"lazy"} src={IconArrow} alt="" />
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          )}

          {!isDeposit && (
            <div className="transactions-container-information">
              {
                !transactionsResponse &&
                <div className={`transactions-container-information__no-deposits`}>
                  <img loading={"lazy"} src={noWithdrawalsImage} alt="" />
                </div>
              }
              {transactionsResponse &&
              transactionsResponse?.filter((item) => item?.type === "Withdrawal").length === 0
                ?
                // (<div>No Withdrawal transaction yet </div>)
                <div className={`transactions-container-information__no-deposits`}>
                  <img loading={"lazy"} src={noWithdrawalsImage} alt="" />
                </div>
                : null}
              {transactionsResponse &&
                transactionsResponse
                  ?.filter((item) => item?.type === "Withdrawal")
                  ?.sort(function(a, b) {
                    return b?.createdAt - a?.createdAt;
                  })
                  .map((item) => (
                    <div className="transactions-container-information-item" onClick={() => {
                      dispatch(setDetailedDepositTransaction(item));
                      history.push("/detailedDepositTransaction");
                    }}
                         key={item?._id}>

                      <div className="transactionsInformation">
                        <div
                          className={`transactionsInformation-img status successfull ${item?.status === "Decline" || item?.status === "Cancelled"
                            ? "statusFailed"
                            : (item.status === "Pending" || item.status === "Initiated")
                              ? "statusPending"
                              : "statusSuccessful"}`}>
                          <img loading={"lazy"} src={item?.status === "Decline" || item?.status === "Cancelled"
                            ? IconFailed
                            : (item.status === "Pending" || item.status === "Initiated")
                              ? IconWaiting
                              : IconSuccessful} alt="" />
                        </div>
                        <div className="transactionsInformation-data">
                          <div className="transactionsInformation-data__id">#{item?._id}</div>
                          <div className="transactionsInformation-data__dateTime">
                            {item?.createdAt && new Date(item?.createdAt)?.toLocaleString("en", {
                              month: "long",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric"
                            })?.replace("at", " ")}
                          </div>
                        </div>
                        <div className="transactionsInformation-amount">
                          ₹ {getCurrencyFormat(item?.amount)}
                        </div>
                        <div className="transactionsInformation-arrow">
                          <img loading={"lazy"} src={IconArrow} alt="" />
                        </div>
                      </div>
                    </div>
                  ))}


            </div>
          )}
        </IonContent>
      </IonPage>
    </>
  ) : (
    <IonPage
      ref={pageRef}
      onScroll={() => {
        setScrollPosition(pageRef?.current?.scrollTop);
      }}
    >
      <div style={{ height: "135px" }}></div>
      {/* <div className="ChangePassword" fullscreen> */}
      <div className="desktopView">
        {isDeposit && (
          <>
            <div className="transactions-container-desktop">
              <div className="transactions-container">
                <div className="transactions-menu">
                  <div
                    onClick={() => toggleTab()}
                    className={`deposite ${isDeposit && "activeTr"} `}
                  >
                    Deposit
                  </div>
                  <div
                    onClick={() => toggleTab()}
                    className={`withdrawal ${!isDeposit && "activeTr"}`}
                  >
                    Withdraw
                  </div>
                </div>
                <div className="transactions-items">
                  {transactionsResponse &&
                    transactionsResponse
                      ?.filter((item) => item?.type === "Deposit")
                      ?.sort(function(a, b) {
                        return b?.createdAt - a?.createdAt;
                      })
                      .map((item) => (
                        <div key={item?._id} className="transactions-item">
                          <div
                            onClick={() => {
                              setOneTransactionResponse(item);
                              // updateOneTransactionDetailsRequest(item?._id);
                            }}
                            className={`transaction-container ${
                              oneTransactionResponse?._id ===
                              item?._id
                                ? "activeItem"
                                : ""
                            }`}
                          >
                            <div className="transaction">
                              <div className="transaction_number">
                                <span className="transaction_number__symbol">
                                  #
                                </span>
                                <span className="transaction_number__number">
                                  {`****${item?._id
                                    .toString()
                                    .substring(20)}`}
                                </span>
                              </div>
                              <div className="transaction_amount">
                                <span>
                                  {" "}
                                  <sup className="transaction_amount-symbol">
                                    &#8377;
                                  </sup>
                                  {getCurrencyFormat(item?.amount)}
                                </span>
                              </div>
                              <div className="transaction_paymentSystem">
                                {item?.paymentType && item?.paymentType.toLowerCase() !=='manual' && (
                                  <img
                                    loading={"lazy"}
                                    src={
                                      item?.paymentType.toLowerCase() === "paytm"
                                        ? PayTM
                                        : item?.paymentType.toLowerCase() === "upi"
                                          ? UPI
                                          : item?.paymentType.toLowerCase() === "imps" || 
                                            item?.paymentType.toLowerCase() == 'bank'
                                            ? IMPS
                                            : item?.paymentType.toLowerCase() === "khelocard"
                                              ? KHELOCARD
                                              : item?.paymentType.toLowerCase() === "payu"
                                                ? PayU
                                                : item?.paymentType.toLowerCase() === "barcode"
                                                ? barcode
                                                : CONFIG.transactionImg + item?.image[0]
                                    }
                                    alt="Payment system icon"
                                  />
                                )}
                                <span className="transaction_paymentSystem_name">
                                  {item?.paymentType? item?.paymentType.toUpperCase() : "NoName"}
                                </span>
                              </div>
                            </div>
                            <div
                              className={`transaction-status ${
                                item?.status === "Pending"
                                  ? "pending"
                                  : item?.status === "Initiated"
                                    ? "pending"
                                    : item?.status === "Decline"
                                      ? "rejected"
                                      : item?.status === "Cancelled"
                                        ? "rejected"
                                        : "approved"
                              } `}
                            >
                              {item?.status}
                            </div>
                            <div className="transaction-date">
                              <span className="transaction-date_date">
                                {item?.createdAt
                                  ?.slice(0, 10)
                                  ?.replaceAll("-", ".")
                                  ?.replace(
                                    /^(\d{4}).(\d{2}).(\d{2})/,
                                    "$3.$2.$1"
                                  )}
                              </span>
                              <span className="transaction-date_time">
                                {item?.createdAt?.slice(11, 19)}
                              </span>
                            </div>

                            {item?.status === "Pending" || item?.status === "Initiated" ? (
                              <div
                                onClick={(e) => {
                                  if (e?.currentTarget) {
                                    updateOneTransactionRequest(item?._id);
                                  }
                                }}
                                className="refresh"
                              >
                                <img
                                  loading={"lazy"}
                                  className="refreshIcon"
                                  src={Refresh}
                                  alt="Refresh icon"
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ))}
                </div>
              </div>

              <div className="detailes-container">
                <>
                  {oneTransactionResponse.type === "Deposit" ? (
                    <div className="detailes-container-content">
                      {oneTransactionResponse ? (
                        <div className="transaction-date">
                          <span className="transaction-date_date">
                            {oneTransactionResponse?.createdAt
                              ?.slice(0, 10)
                              ?.replaceAll("-", ".")
                              ?.replace(/^(\d{4}).(\d{2}).(\d{2})/, "$3.$2.$1")}
                          </span>
                          <span className="transaction-date_time">
                            {oneTransactionResponse?.createdAt?.slice(11, 19)}
                          </span>
                        </div>
                      ) : null}
                      {oneTransactionResponse ? (
                        <div className="detailed-transaction-container">
                          <div className="transaction">
                            <div className="transaction-item">
                              <div className="transaction_number">
                                <span className="transaction_number__symbol">
                                  #
                                </span>
                                <span className="transaction_number__number">
                                  {oneTransactionResponse?.requestId}
                                </span>
                              </div>
                              <div
                                className={`transaction-status ${
                                  oneTransactionResponse?.status === "Pending"
                                    ? "pending"
                                    : oneTransactionResponse?.status ===
                                    "Initiated"
                                      ? "pending"
                                      : oneTransactionResponse?.status ===
                                      "Rejected"
                                        ? "rejected"
                                        : oneTransactionResponse?.status ===
                                        "Cancelled"
                                          ? "rejected"
                                          : "approved"
                                } `}
                              >
                                {oneTransactionResponse?.status}
                              </div>
                            </div>

                            <div className="transaction-item">
                              <div>Amount:</div>
                              <div className="transaction_number__number">
                                {getCurrencyFormat(oneTransactionResponse?.amount)}
                              </div>
                            </div>
                            <div className="transaction-item">
                              <div>Type:</div>
                              <div className="transaction_number__number">
                                {oneTransactionResponse?.gateway
                                  ? oneTransactionResponse?.gateway?.name
                                  : null}
                              </div>
                            </div>
                            <div className="transaction-item">
                              <div>Wallet</div>
                              <div className="transaction_number__number">
                                {oneTransactionResponse?.gateway
                                  ? oneTransactionResponse?.gateway?.acNo
                                  : null}
                              </div>
                            </div>

                            {oneTransactionResponse &&
                            oneTransactionResponse?.status === "Cancelled" ? (
                              <div className="transaction-item">
                                <div>UTR No.:</div>
                                <div className="transaction_number__number">
                                  {oneTransactionResponse?.utr?.slice(
                                    0,
                                    oneTransactionResponse?.utr?.indexOf("~")
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="transaction-item">
                                <div>UTR No.:</div>
                                <div className="transaction_number__number">
                                  {oneTransactionResponse?.utr}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}

                      {oneTransactionResponse &&
                        oneTransactionResponse?.status === "Cancelled" && (
                          <div className="reason-container">
                            <div className="reason-container_title">
                              Reason:
                            </div>
                            <div className="reason-container_text">
                              {oneTransactionResponse?.remarks
                                ? oneTransactionResponse?.remarks
                                : "No reason submitted by user."}
                            </div>
                          </div>
                        )}

                      {/* {oneTransactionResponse &&
                              (oneTransactionResponse.status === 'Approved' ||
                                oneTransactionResponse.status === 'Rejected' ||
                                oneTransactionResponse.status === 'Complete' ||
                                oneTransactionResponse.status ===
                                  'Cancelled') && (
                                <div className="detailed-content__buttons">
                                  <div
                                    className="detailed-content__buttons-back"
                                    onClick={() =>
                                      // history.push('/transactions')
                                      updateOneTransactionDetailsRequest(
                                        oneTransactionResponse.requestId
                                      )
                                    }
                                  >
                                  <button
                                    onClick={() =>
                                      // history.push('/transactions')
                                      updateOneTransactionDetailsRequest(
                                        oneTransactionResponse.requestId
                                      )
                                    }
                                    className="pushable"
                                  >
                                    <span className="shadow"></span>
                                    <span className="edge refreshBtn"></span>
                                    <span className="front refreshBtn">
                                      Refresh
                                    </span>
                                  </button>
                                  </div>
                                </div>
                              )} */}

                      {oneTransactionResponse &&
                        oneTransactionResponse?.status === "Pending" && (
                          <div className="detailed-content__buttons">
                            {/* <div
                                    className="detailed-content__buttons-edit"
                                    onClick={() => {
                                      setOpenPopUp(true)
                                      seteditPoUP(true)
                                      console.log(
                                        'editPoUP in component',
                                        editPoUP
                                      )
                                    }}
                                  > */}
                            <button
                              onClick={() => {
                                setOpenPopUp(true);
                                seteditPoUP(true);
                                console.log("editPoUP in component", editPoUP);
                              }}
                              className="pushable"
                            >
                              <span className="shadow"></span>
                              <span className="edge editBtn"></span>
                              <span className="front editBtn">Edit</span>
                            </button>
                            {/* </div> */}

                            {/* <div
                                    className="detailed-content__buttons-back"
                                    onClick={() =>
                                      updateOneTransactionRequest(
                                        oneTransactionResponse.requestId
                                      )
                                    }
                                  > */}
                            <button
                              onClick={() =>
                                updateOneTransactionRequest(
                                  oneTransactionResponse?.requestId
                                )
                              }
                              className="pushable"
                            >
                              <span className="shadow"></span>
                              <span className="edge refreshBtn"></span>
                              <span className="front refreshBtn">Refresh</span>
                            </button>
                            {/* </div> */}

                            {/* <div
                                    className="detailed-content__buttons-cancel"
                                    onClick={() => {
                                      setOpenPopUp(true)
                                      seteditPoUP(false)
                                    }}
                                  > */}
                            <button
                              onClick={() => {
                                setOpenPopUp(true);
                                seteditPoUP(false);
                              }}
                              className="pushable"
                            >
                              <span className="shadow"></span>
                              <span className="edge cancelBtn"></span>
                              <span className="front cancelBtn">Cancel</span>
                            </button>
                            {/* </div> */}
                          </div>
                        )}
                    </div>
                  ) : null}
                </>
              </div>
            </div>
          </>
        )}
        {!isDeposit && (
          <>
            <div className="transactions-container-desktop">
              <div className="transactions-container">
                <div className="transactions-menu">
                  <div
                    onClick={() => toggleTab()}
                    className={`deposite ${isDeposit && "activeTr"} `}
                  >
                    Deposit
                  </div>
                  <div
                    onClick={() => toggleTab()}
                    className={`withdrawal ${!isDeposit && "activeTr"}`}
                  >
                    Withdraw
                  </div>
                </div>
                <div className="transactions-items">
                  {transactionsResponse &&
                    transactionsResponse
                      ?.filter((item) => item?.type === "Withdrawal")
                      ?.sort(function(a, b) {
                        return b?.createdAt - a?.createdAt;
                      })
                      ?.map((item) => (
                        <div key={item?._id} className="transactions-item">
                          <div
                            onClick={() => {
                              setOneTransactionResponse(item);
                              // updateOneTransactionDetailsRequest(item?._id);
                            }}
                            className={`transaction-container ${
                              oneTransactionResponse?._id ===
                              item?._id
                                ? "activeItem"
                                : ""
                            }`}
                          >
                            <div className="transaction">
                              <div className="transaction_number">
                                <span className="transaction_number__symbol">
                                  #
                                </span>
                                <span className="transaction_number__number">
                                  {`****${item?._id
                                    ?.toString()
                                    ?.substring(20)}`}
                                </span>
                              </div>
                              <div className="transaction_amount">
                                <span>
                                  {" "}
                                  <sup className="transaction_amount-symbol">
                                    &#8377;
                                  </sup>
                                  {getCurrencyFormat(item?.amount)}
                                </span>
                              </div>
                              <div className="transaction_paymentSystem">
                                {item.paymentType && item.paymentType.toLowerCase() !== 'manual' && (
                                  <img
                                    src={
                                      item?.paymentType.toLowerCase() === 'paytm'
                                        ? PayTM
                                        : item?.paymentType.toLowerCase() === 'upi'
                                        ? UPI
                                        : item?.paymentType.toLowerCase() === 'imps' ||
                                          item?.paymentType.toLowerCase() == 'bank'
                                        ? IMPS
                                        : null
                                    }
                                    alt="Payment system icon"
                                  />
                                )}
                                {/* <img src={IMPS} alt="Payment system icon" /> */}
                                <span className="transaction_paymentSystem_name">
                                  {item.paymentType? item?.paymentType.toUpperCase() : 'NoName'}
                                  {/* IMPS */}
                                </span>
                              </div>
                            </div>
                            <div
                              className={`transaction-status ${
                                item?.status === "Pending"
                                  ? "pending"
                                  : item?.status === "Decline"
                                    ? "rejected"
                                    : item?.status === "Cancelled"
                                      ? "rejected"
                                      : "approved"
                              } `}
                            >
                              {item?.status}
                            </div>
                            <div className="transaction-date">
                              <span className="transaction-date_date">
                                {item?.createdAt
                                  ?.slice(0, 10)
                                  ?.replaceAll("-", ".")
                                  ?.replace(
                                    /^(\d{4}).(\d{2}).(\d{2})/,
                                    "$3.$2.$1"
                                  )}
                              </span>
                              <span className="transaction-date_time">
                                {item?.createdAt?.slice(11, 19)}
                              </span>
                            </div>
                            {item?.status === "Pending" ? (
                              <div
                                onClick={(e) => {
                                  if (e?.currentTarget) {
                                    updateOneTransactionRequest(item?._id);
                                  }
                                }}
                                className="refresh"
                              >
                                <img
                                  className="refreshIcon"
                                  src={Refresh}
                                  alt="Refresh icon"
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ))}
                </div>
              </div>

              <div className="detailes-container W">
                <>
                  {oneTransactionResponse?.type === "Withdrawal" ? (
                    <div className="detailes-container-content">
                      {oneTransactionResponse ? (
                        <div className="transaction-date">
                          <span className="transaction-date_date">
                            {oneTransactionResponse?.createdAt
                              ?.slice(0, 10)
                              ?.replaceAll("-", ".")
                              ?.replace(/^(\d{4}).(\d{2}).(\d{2})/, "$3.$2.$1")}
                          </span>
                          <span className="transaction-date_time">
                            {oneTransactionResponse?.createdAt?.slice(11, 19)}
                          </span>
                        </div>
                      ) : null}
                      {oneTransactionResponse ? (
                        <div className="detailed-transaction-container">
                          <div className="transaction">
                            <div className="transaction-item">
                              <div className="transaction_number">
                                <span className="transaction_number__symbol">
                                  #
                                </span>
                                <span className="transaction_number__number">
                                  {oneTransactionResponse?.requestId}
                                </span>
                              </div>
                              <div
                                className={`transaction-status ${
                                  oneTransactionResponse?.status === "Pending"
                                    ? "pending"
                                    : oneTransactionResponse?.status ===
                                    "Rejected"
                                      ? "rejected"
                                      : oneTransactionResponse?.status ===
                                      "Cancelled"
                                        ? "rejected"
                                        : "approved"
                                } `}
                              >
                                {oneTransactionResponse?.status}
                              </div>
                            </div>

                            <div className="transaction-item">
                              <div>Amount:</div>
                              <div className="transaction_number__number">
                                {getCurrencyFormat(oneTransactionResponse?.amount)}
                              </div>
                            </div>
                            <div className="transaction-item">
                              <div>Type:</div>
                              <div className="transaction_number__number">
                                {oneTransactionResponse?.gateway
                                  ? oneTransactionResponse?.gateway?.name
                                  : "IMPS"}
                              </div>
                            </div>
                            <div className="transaction-item">
                              <div>IFSC code:</div>
                              <div className="transaction_number__number">
                                {oneTransactionResponse?.account
                                  ? oneTransactionResponse?.account?.IFSC
                                  : null}
                              </div>
                            </div>
                            <div className="transaction-item">
                              <div>Account number:</div>
                              <div className="transaction_number__number">
                                {oneTransactionResponse?.account
                                  ? oneTransactionResponse?.account?.accountNo
                                  : null}
                              </div>
                            </div>
                            <div className="transaction-item">
                              <div>Account name:</div>
                              <div className="transaction_number__number">
                                {oneTransactionResponse?.account
                                  ? oneTransactionResponse?.account
                                    ?.accountHolderName
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {oneTransactionResponse &&
                        oneTransactionResponse?.status === "Cancelled" && (
                          <div className="reason-container">
                            <div className="reason-container_title">
                              Reason:
                            </div>
                            <div className="reason-container_text">
                              {oneTransactionResponse?.remarks
                                ? oneTransactionResponse?.remarks
                                : "No reason submitted by user."}
                            </div>
                          </div>
                        )}

                      {/* {oneTransactionResponse &&
                              (oneTransactionResponse.status === 'Approved' ||
                                oneTransactionResponse.status === 'Rejected' ||
                                oneTransactionResponse.status === 'Complete' ||
                                oneTransactionResponse.status ===
                                  'Cancelled') && (
                                <div className="detailed-content__buttons">
                                  <div
                                    className="detailed-content__buttons-back"
                                    onClick={() =>
                                      history.push('/transactions')
                                    }
                                  >
                                    Refresh
                                  </div>
                                </div>
                              )} */}

                      {oneTransactionResponse &&
                        oneTransactionResponse?.status === "Pending" && (
                          <div className="detailed-content__buttons">
                            {/* <div
                                    className="detailed-content__buttons-back"
                                    onClick={() =>
                                      updateOneTransactionRequest(
                                        oneTransactionResponse.requestId
                                      )
                                    }
                                  >
                                    Refresh
                                  </div> */}
                            <button
                              onClick={() =>
                                updateOneTransactionRequest(
                                  oneTransactionResponse?.requestId
                                )
                              }
                              className="pushable"
                            >
                              <span className="shadow"></span>
                              <span className="edge refreshBtn"></span>
                              <span className="front refreshBtn">Refresh</span>
                            </button>

                            {/* <div
                                    className="detailed-content__buttons-cancel"
                                    onClick={() => {
                                      setOpenPopUp(true)
                                      seteditPoUP(false)
                                    }}
                                  >
                                    Cancel
                                  </div> */}
                            <button
                              onClick={() => {
                                setOpenPopUp(true);
                                seteditPoUP(false);
                              }}
                              className="pushable"
                            >
                              <span className="shadow"></span>
                              <span className="edge cancelBtn"></span>
                              <span className="front cancelBtn">Cancel</span>
                            </button>
                          </div>
                        )}
                    </div>
                  ) : null}
                </>
              </div>
            </div>
          </>
        )}
      </div>
      {/* </div> */}
      {oneTransactionResponse && (
        <PopUp
          data={oneTransactionResponse}
          content={editPoUP}
          open={openPopUp}
          onDidDismiss={() => setOpenPopUp(false)}
          refresh={() =>
            updateOneTransactionRequest(oneTransactionResponse?.requestId)
          }
          scrollPosition={scrollPosition}
        />
      )}
      <FooterDesktop />
    </IonPage>
  );
};

export default connectTransactions()(Transactions);

