import { IonButton } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import "./SportButton.scss";
import { connectSportButton } from "./SportButton.connect";
import { openSlotCasinoPopUp } from "../../../../store/slotCasinoPopUp/slotCasinoPopUp.slice";
import { setLoginNew } from "../../../../store/LoginNew/loginNew.slice";
// import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { setLoginNavigationData } from "../../../../store/loginNavigation/LoginNavigation.slice";

const SportButton = ({
                      //  itemButton: { name, icon, redirect, backgroundImage, backgroundColour, id },
                       itemButton: { name, icon, eventTypeId, backgroundImage, backgroundColour, id },
                       auth,
                       actions,
                       elementsParams,
                       menu
                     }) => {
  const history = useHistory();
  const dispatch = useDispatch();


  const gotoEvent = () => {
    if(name === 'Casino') {
      if(auth) {
        history.push('/livecasino-lobby');
        dispatch(openSlotCasinoPopUp());
      }else{
        dispatch(setLoginNew(true));
        dispatch(setLoginNavigationData({page: 'livecasino-lobby', ids: []}));
      }
    }else {
      actions.setIsNavigationOpen(true);
      setTimeout(() => { actions.setIsNavigationOpen(false);}, 400);
      setTimeout(() => { actions.setIsAnimatedMenu(false);}, 400);
      
      actions.setIsNavigationID(eventTypeId);
      actions.setIsNavigationName(name);

      const data = {
        eventTypeId: eventTypeId
      };
  
      setTimeout(() => {
        actions.requestHomeAllCompetition(data);
      }, 500)
    }

  };
  return (
    <>
      <IonButton className="sport-button" mode="md" id={id}>
        <div 
          className="sport-button__content" 
          style={{ backgroundColor: `${backgroundColour}` }}
          onClick={() => { gotoEvent(); }} 
        >
          <img className="sport-button__background-img" src={backgroundImage} alt="" />
          <div className={`sport-button__content-text`}>
            <img src={icon} alt="" />
            <span>{name}</span>
          </div>
        </div>
      </IonButton>
    </>

  );
};

export default connectSportButton()(SportButton);
