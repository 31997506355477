import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './PopularGamesGridList.scss'
import { IonList, IonListHeader } from "@ionic/react";
import EventTypeBanners from '../EventTypeBanners/EventTypeBanners';
import { headerType } from '../../helpers/InPlayPopular.helper';
import { requestSlotsGamesID } from '../../store/slotsGames/slotsGames.thunks'
import { setIsPopUpOpen } from '../../store/slotsPopUp/slotsPopUp.slice'
import { connectSlotsGamesLobby } from '../../pages/SlotsGamesLobby/SlotsGamesLobby.connect'
import JiliPopUp from '../SlotsJiliPopUp'
import { useHistory } from 'react-router';
import { openSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice';
import { setLoginNavigationData } from '../../store/loginNavigation/LoginNavigation.slice';

const PopularGamesGridList = ({ itemHeaderData, itemsData, type, actions }) => {
    const auth = useSelector((state) => state.auth);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const slider = useRef(null);
    const [isDown, setIsDown] = useState(false);
    const [isSlide, setIsSlide] = useState(false);
    const [startX, setStartX] = useState("");
    const [scrollLeft, setScrollLeft] = useState("");
    const [jiliGame, setJiliGame] = useState();
    const isPopUpOpen = useSelector((state) => state.slotsPopUp.isPopUpOpen)
    const history = useHistory()

    const onOpenGame = (gameId, tableId) => {
        if(user?.verifytoken) {
            history.push(`/casino_live/${gameId}/${tableId}`);
            dispatch(openSlotCasinoPopUp())
        }else{
            actions.setLoginNew(true);
            dispatch(setLoginNavigationData({ page: 'casino_live', ids: [gameId, tableId] }));
        }
        
        // if(auth) {
        //     if (id.indexOf('jili') !== -1) {
        //         console.log('open')
        //         setJiliGame(item)
        //         dispatch(setIsPopUpOpen(true))
        //     } else {
        //         dispatch(requestSlotsGamesID(id))
        //         history.push(`/live_casino_slot/${id}`)
        //     }
        // } else {
        // actions.setLoginNew(true)
        // }
    }

  const getBanner = () => {
        if (headerType['INPLAY'] == type) {
             return <EventTypeBanners eventType={itemHeaderData?.id} />
        } else return null
    }

    const renderHeader = () => {
        return (
            <IonListHeader 
                className="inplay-item-list-exchange__header "
                style={{backgroundColor: '#ff4500', color: '#fff'}}
            >
                <div className="inplay-item-list-exchange__header-content">
                    <div className="inplay-item-list-exchange__header-logo">
                        <i className={`inplay-content__logo-icon inplay-content__logo-icon--exchange-games inplay-color-icon`} />
                        {itemHeaderData?.name}
                    </div>
                </div>
            </IonListHeader>
        );
    }

    return (
        <IonList className="inplay-item-list_popularGames">
            {isPopUpOpen ? <JiliPopUp item={jiliGame} /> : null}
            {getBanner()}
            {itemHeaderData ? renderHeader() : null}
            <div className="popularGames-content"
                 ref={slider}
                 onMouseDown={(e) => {
                     setIsDown(true)
                     setStartX(e.pageX - slider.current.offsetLeft)
                     setScrollLeft(slider.current.scrollLeft)
                 }}
                 onMouseUp={() => {
                     if (isSlide) {
                         setTimeout(() =>    setIsSlide(false), 500)
                     }
                     setIsDown(false)
                 }}
                 onMouseMove={(e) => {
                     if(!isDown) return;
                     e.preventDefault();
                     setIsSlide(true)
                     const x = e.pageX - slider.current.offsetLeft;
                     const walk = (x - startX) * 3; //scroll-fast
                     slider.current.scrollLeft = scrollLeft - walk;
                 }}
                 onMouseLeave={() => {
                     setIsDown(false)
                     setIsSlide(false)
                 }}
            >
                {itemsData && itemsData.map((item, index) => {
                    return (
                        (item?.ImageURL)&& 
                        <div className="popularGames-item"
                            id={item.ID} 
                            key={index}
                            style={{backgroundColor: `${item?.backgroundColour}`}}
                            onClick={isSlide ? () => {} : ()=> onOpenGame(item?.gameId, item?.tableId)}
                        >
                            <div className="popularGames-img-body" style={{ backgroundImage: `url(${item?.ImageURL || itemHeaderData?.banner})`}}>
                              <span className="popularGames-item-name">{item?.name}</span>
                            </div>
                        </div>
                    )
                })}

            </div>
        </IonList>

    )
}
export default connectSlotsGamesLobby()(PopularGamesGridList)
// export default
