import React, { useEffect, useState, useRef, useCallback } from 'react'
import { IonContent, IonPage, IonHeader,  } from '@ionic/react'
import { connectLiveCasinoLobby } from './LiveCasinoLobby.connect'
import { useHistory, useParams } from 'react-router-dom'
import './LiveCasinoLobby.scss'
import LobbyTopImg from '../../assets/images/liveCasinoLobby/livecasinoLobby.svg'
// import LobbyLines from '../../assets/images/liveCasinoLobby/bgLinesCasino.jpg'
// import LobbyLine from '../../assets/images/liveCasinoLobby/bgLineCasino.svg'
import LobbyMobileTopImg from '../../assets/images/liveCasinoLobby/LiveCasinoMobileTop.png'
import Toolbar from '../../components/Toolbar/Toolbar'
// import mobileBgImg from '../../assets/images/exchange_games_bg_mobile.jpg'

import FooterDesktop from '../../components/FooterDesktop/FooterDesktop';
// import { wifi } from 'ionicons/icons'
import SlotsCasinoPopUp from '../../components/SlotsCasinoPopup';
import { CasinoHeader } from '../../constants/Casino/CasinoHeader'


const LiveCasinoLobby = ({ inplayPopular, actions, liveCasino, liveCasinoSearch, auth, elementsParams, quickButtonSlots, slotCasinoPopUp }) => {
  const [seeAllGames, setSeeAllGames] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(null); 
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const timeoutRef = useRef(null);
  const gameViewRef = useRef(null);
  const [gamesData, setGamesData] = useState([]);
  const {gameType} = useParams();
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  const history = useHistory()
  const wrapper = useRef(null);

  useEffect(() => {
    console.log(history.location)
    if(!auth) {
      history.push("/default-page");
    }
  }, [auth])

  useEffect(() => {
    if(!!gameType) {
      const requiredFilter = CasinoHeader.find((item) => item?.filterName === gameType);
      if(requiredFilter) {
        setSelectedFilter(requiredFilter)
      }else{
        setSelectedFilter(CasinoHeader[0]);
      }
    }else {
      setSelectedFilter(CasinoHeader[0]);
    }
  }, [gameType]);



  useEffect(() => {
    if(!!selectedFilter?.filterName) {
      handleClick(selectedFilter?.filterName);
    }
  }, [selectedFilter])


  const handleClick = (type = 'LIVECASINO') => {
    actions.requestLiveCasinoLobby(type)
  };


  useEffect(() => {
    setGamesData(liveCasino);
    if(inputRef1.current) inputRef1.current.value = '';
    if(inputRef2.current) inputRef2.current.value = '';
    scrollIntoView();
  }, [liveCasino])


  const scrollIntoView = () => {
    if(gameViewRef && gameViewRef.current) {
      gameViewRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }


  const onOpenGame = (data) => {
    const id = data?.id;
    const tableId = data?.tableId || '';

    history.push(`/casino_live/${id}/${tableId}`);
  }


  const debouncedSearch = useCallback((searchTerm) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (searchTerm === '') {
        setGamesData(liveCasino);
      } else {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filteredData = liveCasino.filter((item) =>
          item.name.toLowerCase().includes(lowerCaseSearchTerm)
        );
        setGamesData(filteredData);
      }
    }, 300); // Adjust the debounce delay as needed
  }, [liveCasino]);
  

  const onSearchGames = (value) => {
    debouncedSearch(value);
  }


  return (
    <>
    {slotCasinoPopUp?.isOpen && <SlotsCasinoPopUp/>}
    <div ref={wrapper} className='liveCasinoLobbyWrapper'></div>
      {width < 1 ? (
        <IonPage>
          <IonContent>
            <div></div>
          </IonContent>
        </IonPage>
      ) : (
        <IonPage>
          {width < 1024 ?
            <IonHeader>
              <Toolbar/>
            </IonHeader>
            :
            <></>
          }
          <IonContent>
            <div className="bgCasino">
              <div className="liveCasino-header">
                {(width < 1024)?
                  <img
                    loading={"lazy"}
                    className="liveCasino-header__img"
                    src={LobbyMobileTopImg}
                    alt=""
                  />
                  :
                  <img
                    loading={"lazy"}
                    className="liveCasino-header__img"
                    src={LobbyTopImg}
                    alt=""
                  />
                }

                <div class="lamp">
                  <div class="lava">
                    <div className="liveCasino-header__text">
                      <div className="liveCasino-header__text-name animate-charcter ">
                        {gameType? selectedFilter?.name : 'Internation Casino'}
                      </div>
                      <div className="liveCasino-header__text-credo">
                        Play. Win. Enjoy.
                      </div>
                    </div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob"></div>
                    <div class="blob2">
                      <svg xlinkHref="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="url('#myGradient')" viewBox="0 0 310 350">
                        <path d="M156.4,339.5c31.8-2.5,59.4-26.8,80.2-48.5c28.3-29.5,40.5-47,56.1-85.1c14-34.3,20.7-75.6,2.3-111  c-18.1-34.8-55.7-58-90.4-72.3c-11.7-4.8-24.1-8.8-36.8-11.5l-0.9-0.9l-0.6,0.6c-27.7-5.8-56.6-6-82.4,3c-38.8,13.6-64,48.8-66.8,90.3c-3,43.9,17.8,88.3,33.7,128.8c5.3,13.5,10.4,27.1,14.9,40.9C77.5,309.9,111,343,156.4,339.5z"/>
                        <defs>
                          <linearGradient id="myGradient" gradientTransform="rotate(90)">
                            <stop offset="0%"  stopColor="#eeaeca" />
                            <stop offset="10%" stopColor="#d99cce" />
                            <stop offset="24%" stopColor="#de86e9" />
                            <stop offset="47%" stopColor="#c996e9" />
                            <stop offset="68%" stopColor="#a5aee9" />
                            <stop offset="100%" stopColor="#94bbe9" />

                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div class="blob3">
                      <svg xlinkHref="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="url('#myGradient')" viewBox="0 0 310 350">
                        <path d="M156.4,339.5c31.8-2.5,59.4-26.8,80.2-48.5c28.3-29.5,40.5-47,56.1-85.1c14-34.3,20.7-75.6,2.3-111  c-18.1-34.8-55.7-58-90.4-72.3c-11.7-4.8-24.1-8.8-36.8-11.5l-0.9-0.9l-0.6,0.6c-27.7-5.8-56.6-6-82.4,3c-38.8,13.6-64,48.8-66.8,90.3c-3,43.9,17.8,88.3,33.7,128.8c5.3,13.5,10.4,27.1,14.9,40.9C77.5,309.9,111,343,156.4,339.5z"/>
                        <defs>
                          <linearGradient id="myGradient" gradientTransform="rotate(90)">
                            <stop offset="0%"  stopColor="#eeaeca" />
                            <stop offset="10%" stopColor="#d99cce" />
                            <stop offset="24%" stopColor="#de86e9" />
                            <stop offset="47%" stopColor="#c996e9" />
                            <stop offset="68%" stopColor="#a5aee9" />
                            <stop offset="100%" stopColor="#94bbe9" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                      <filter id="goo">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                        <feBlend in="SourceGraphic" in2="goo" />
                      </filter>
                    </defs>
                  </svg>
                </div>
              </div>

            <div className={`liveCasino-content ${width < 1024 ? 'mobile' : ''}`}>
              <div className="liveCasino-content__menu">
                {(!gameType && width < 1024) ?
                  <>
                    <div style={{
                        margin: '8px 10px 8px 10px',
                        display: 'flex',
                        justifyContent: 'end' 
                      }}
                    >
                      <div className="inputContainerMobile-live">
                        <input
                          ref={inputRef2}
                          placeholder="SEARCH GAMES"
                          className="inputContainerMobile-live__input"
                          name="search"
                          autoComplete="off"
                          onChange={(e) => onSearchGames(e.target.value)}
                        />
                      </div>
                    </div>
                      
                    <div className="liveCasino-content__menu-filerMobile">
                      <div className="liveCasino-content__menu-filerMobile-column">
                        <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => {setSelectedFilter(CasinoHeader[0]);}}>Live Casino</div>
                        <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => {setSelectedFilter(CasinoHeader[1]);}}>Instant Win</div>
                        <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => {setSelectedFilter(CasinoHeader[2]);}}>Table Game</div>
                        <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => {setSelectedFilter(CasinoHeader[3]);}}>Virtual Sports</div>
                      </div>

                      <div className="liveCasino-content__menu-filerMobile-column">
                        <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => {setSelectedFilter(CasinoHeader[4]);}}>Video Poker</div>
                        <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => {setSelectedFilter(CasinoHeader[5]);}}>Shooting</div>
                        <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => {setSelectedFilter(CasinoHeader[6]);}}>Scretch Card</div>
                        <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => {setSelectedFilter(CasinoHeader[7]);}}>Slot</div>
                      </div>
                    </div>
                  </>
                  :
                  (!gameType)?
                  <>
                    <div className="liveCasino-content__menu-filter">
                      <div className="liveCasino-content__menu-filter-row">
                        <div className="a" onClick={() => {setSelectedFilter(CasinoHeader[0]);}}>
                          <span>Live Casino</span>
                          <div className="liquid"></div>
                        </div>
                        <div className="a" onClick={() => {setSelectedFilter(CasinoHeader[1]);}}>
                          <span>Instant Win</span>
                          <div className="liquid"></div>
                        </div>
                        <div className="a" onClick={() => {setSelectedFilter(CasinoHeader[2]);}}>
                          <span>Table Game</span>
                          <div className="liquid"></div>
                        </div>
                        
                        <div className="inputContainer">
                          <input
                            ref={inputRef1}
                            placeholder="SEARCH GAMES"
                            className="inputContainer__input"
                            name="search"
                            autoComplete="off"
                            onChange={(e) => onSearchGames(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="liveCasino-content__menu-filter">
                      <div className="liveCasino-content__menu-filter-row">
                        <div className="a" onClick={() => {setSelectedFilter(CasinoHeader[4]);}}>
                          <span>Video Poker</span>
                          <div className="liquid"></div>
                        </div>

                        <div className="a" onClick={() => {setSelectedFilter(CasinoHeader[5]);}}>
                          <span>Shooting</span>
                          <div className="liquid"></div>
                        </div>
                        <div className="a" onClick={() => {setSelectedFilter(CasinoHeader[6]);}}>
                          <span>Scretch Card</span>
                          <div className="liquid"></div>
                        </div>

                        <div className="a" onClick={() => {setSelectedFilter(CasinoHeader[3]);}}>
                          <span>Virtual</span>
                          <div className="liquid"></div>
                        </div>

                        <div className="a" onClick={() => {setSelectedFilter(CasinoHeader[7]);}}>
                          <span>Slot</span>
                          <div className="liquid"></div>
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  null
                }

                <div className="liveCasino-content__menu-games">
                  <div className="liveCasino-content__menu-games__allgames">
                    
                    <>
                      <div className="liveCasino-content__menu-games__allgames-title">
                        <span ref={gameViewRef} className="liveCasino-content__menu-games__allgames-title-ref"></span>
                        <div className="liveCasino-content__menu-games__allgames-title-name">
                          {selectedFilter?.name || 'All games'}
                        </div>
                        <div className="liveCasino-content__menu-games__allgames-title-seeAll"
                          onClick = {() => {setSeeAllGames(!seeAllGames)}}
                        >
                          {width>=1024? seeAllGames ? 'See less' : 'See all' : null}
                        </div>
                      </div>

                      <div style={{width: '100%', overflowX: 'auto'}}>
                      
                        <div 
                          style={(seeAllGames || width<1024)? 
                            {height:'100%', flexDirection: 'row', justifyContent: 'space-around', paddingBottom: '20px'}: 
                            {flexDirection: 'row', justifyContent: 'space-around'}
                          }
                          className="liveCasino-content__menu-games__allgames-items"
                        >
                          {(gamesData?.length>0)&& gamesData?.map((item) => {
                            return (
                              <div className="liveCasino-content__menu-games__allgames-items-item" key={item?.id}>
                                <div className='altBackgroundCasino'>
                                  <span>{item?.name}</span>
                                  <img
                                    loading={"lazy"}
                                    src={item?.images[2]?.url}
                                    alt=" "
                                    onClick = {() => onOpenGame(item)}
                                  />
                                  <div className='img-gamename'>{item?.name}</div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </>

                    </div>
                  </div>
                </div>
              </div>
              {width > 1024 && <FooterDesktop />}
            </div>
          </IonContent>
         </IonPage>
      )}
    </>
  )
}

export default connectLiveCasinoLobby()(LiveCasinoLobby)
