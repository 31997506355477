import React, { useRef } from "react";
import '../../LiveSection/LiveSection.scss';
import ballByBallImg from '../../../assets/images/banners/ball_by_ball-banner.webp';
// const ballByBallImg = 'https://winbuzz360.com/assets/images/Banner/ball_by_ball_banner.jpeg';



const VirtualLiveSection = ({videoUrl, eventTypeId, data}) => {  
    const width = window.innerWidth;
    let iframeRef = useRef();
    let iframeWidth = iframeRef?.current?.offsetWidth;
    let wrapperRef = useRef();
    let wrapperWitdh = wrapperRef?.current?.offsetWidth;
    let coords = (wrapperWitdh - iframeWidth) / 2 + 40;
    let mTimer = 0;
    

    function isValidUrl() {
        try {
            new URL(videoUrl);
            return true;
        } catch (_) {
            return false;
        }
    }


    if (data?.timers <= 25) {
        mTimer = 0;
    } else {
        mTimer = data?.timers - 25;
    }


    return (
        <section className={`LiveSection virtual`}>
            <div
                className={`LiveSection__content eventPageFixLiveSection LiveSection__content-withStats`}
                style={{background: 'black'}}
                ref={wrapperRef}
            >
                {eventTypeId == 'v9'?
                    <iframe
                        className="LiveSection__content__video sportVisualisation virtual__content__video"
                        style={width<1024? {height: width*0.55} : {}}
                        allow="autoplay"
                        allowFullScreen={true}
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        scrolling="no"
                        title="Live video"
                        src={videoUrl}
                        ref={iframeRef}
                    ></iframe>
                    :
                    <>
                        <div className="bbbvideowrap">
                            <div className="bbbvideowrapiner">
                                {isValidUrl()?
                                    <iframe 
                                        className="LiveSection__content__video sportVisualisation virtual__content__video"
                                        style={width<1024? {height: width*0.55} : {}}
                                        allow="autoplay"
                                        allowFullScreen={true}
                                        frameBorder="0"
                                        marginHeight="0"
                                        marginWidth="0"
                                        scrolling="no"
                                        title="Live video"
                                        src={videoUrl}
                                    ></iframe>
                                    :
                                    <div className="bannertimerbox">
                                        <div style={{position: 'relative'}}>
                                            <img src={ballByBallImg} width="100%" />
                                            <div className={!!data?.ballrun? "animate__animated " : ''}>
                                                <div className={!!data?.ballrun? "rotate-down-bounce" : ''}>
                                                    {data?.ballrun}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="timertext">
                                            {mTimer>0? <span>00:{mTimer}</span> : null}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="bbbvideoinfo">
                                <div className="bbbvideoinfoiner">
                                    <div className="bbbvideoinfotit" style={{borderRight: '1px solid #fff'}}>BALL BY BALL</div>
                                    <div className="bbbvideoinfotit">&nbsp;&nbsp;Round ID: 171920315666683</div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </section>
    );
};

export default VirtualLiveSection;
