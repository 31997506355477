import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestSearchResults } from '../../store/search/search.thunks'
import { setIsAnimatedText, setSearchValues } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { setClientWidth } from '../../store/elementsParams/elementsParams.slice'
import { setClientWidthThunk } from '../../store/elementsParams/getElementsParams.thunk'
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'
import { setLoginNavigationData } from '../../store/loginNavigation/LoginNavigation.slice'

export const connectSearchDesktop = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      search: state.search,
      menu: state.menu,
      auth: state.auth,
      animatedText: state.navigationHeaderMenu.animatedText,
      elementsParams: state.elementsParams,
      clientWidth: state.elementsParams.clientWidth,
      searchValues: state.navigationHeaderMenu.searchValue,
      freeHomeAllMarkets: state.homeMarketEvents.freeHomeAllMarkets,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSearchResults,
      setIsAnimatedText,
      setSearchValues,
      setClientWidth,
      setClientWidthThunk,
      setLoginNew,
      setLoginNavigationData
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
