import './index.scss'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '../../assets/images/slotsGames/crossPopUp.svg'
import { setIsPopUpOpen } from '../../store/slotsPopUp/slotsPopUp.slice'
import { requestJiliSlot } from '../../store/slotsPopUp/slotsPopUp.thunks'
import { requestJILIGamesID, requestSlotsGamesID } from '../../store/slotsGames/slotsGames.thunks'
import { getCurrencyFormatWithZeroAfterDot } from '../../helpers/getCurrencyFormat.helper'
import { createPortal } from "react-dom";
import slotsCasinoImg from './slotsCasinoPopUp.svg';
import closeButton from './closeButton.svg';
import { setSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice'


const modalRoot = document.querySelector("#modal-root");

const SlotsCasinoPopUp = ({
  scrollPosition,
  item,
 }) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const { balance, user } = useSelector((state) => state);
  const jiliDataState = useSelector((state) => state.slotsPopUp.jiliData);

  const [jiliData, setJiliData] = useState(item || jiliDataState)

  const [inputValue, setInputValue] = useState('')


  const sendDeposit = () => {
    dispatch(requestJiliSlot(inputValue))
    let id = jiliData?.ID ?? jiliData?.id;
    dispatch(requestJILIGamesID(id))
    history.push(`/live_casino_slot/${id}`)
    setTimeout(
      () => {
        dispatch(setIsPopUpOpen(false))
      }, 1000)
  }

  return createPortal (
    <div className="slotsCasino-pop-up"
      style={{top: scrollPosition + "px"}}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
            dispatch(setIsPopUpOpen(false));
            console.log('e', e);
        }
      }}>
      <div className="slotsCasino-pop-up__content">
        <img className="slotsCasino-pop-up__content-img" src={slotsCasinoImg} alt=''/>
        <img className="slotsCasino-pop-up__content-closeButton" onClick={() => dispatch(setSlotCasinoPopUp(false))} src={closeButton} alt=''/>
        <div className="slotsCasino-pop-up__content-title">PLEASE NOTE</div>
        <div className="slotsCasino-pop-up__content-descr">(1 Point = ₹ 10)</div>
        <div className="slotsCasino-pop-up__content-welcome">Welcome to your new casino lobby</div>
        <div className="slotsCasino-pop-up__content-checkout">Checkout the exciting Live Casino tables, Slots and much more, upto 200+ game and counting...</div>
        <div className="slotsCasino-pop-up__content-button" onClick={() => dispatch(setSlotCasinoPopUp(false))}>OK, I AGREE !</div>
      </div>
    </div>
    , modalRoot
  )
}

export default SlotsCasinoPopUp;
