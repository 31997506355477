import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { connectBetSlipDesktop } from './BetSlipDesktop.connect'
import DesktopBetPlacing from '../DesktopBetPlacing/DesktopBetPlacing'
import OpenBets from '../OpenBets/OpenBets'
import EditStakesPopUp from './EditStakesPopUp/EditStakesPopUp'

import './BetSlipDesktop.scss'
import { Socket } from '../../services/socket'
import { useHistory } from 'react-router'

const BetSlipDesktop = ({
  betPlacing,
  actions,
  openBets,
  eventOpenBets,
  allEventsOpenBets,
  auth,
  user
}) => {
  const [isOpenBets, setIsOpenBets] = useState(false)
  const [isThisEvent, setIsThisEvent] = useState(
    localStorage.getItem('page')?.indexOf('event-page') < 0 ? false : true
  )
  const setInfoDispatch = useSelector((state) => state.openBetsList.detailed)
  const setThisEventDispatch = useSelector((state) => state.openBetsList.event)
  const width = window.innerWidth;
  const history = useHistory();
  const isPopUpOpenDispatch = useSelector(
    (state) => state.editStakesPopUp.isOpen
  )

  useEffect(() => {
    actions.setThisEvent(false)
    // if (!isThisEvent) {
    // }
  }, [isOpenBets])

  useEffect(() => {
    setIsOpenBets(false)
  }, [betPlacing])

  useEffect(() => {
    if (!betPlacing.betPlacingPopoverStage2) {
      setIsOpenBets(true)

    }
  }, [betPlacing.betPlacingPopoverStage2])

  useEffect(() => {
    setIsThisEvent(localStorage.getItem('page')?.indexOf('event-page') < 0 ? false : true);
  }, [isOpenBets])
  

  // useEffect(() => {
  //   requestUnsettledBets();
      
  // }, [history.location, setThisEventDispatch]);

  // const requestUnsettledBets = () => {
  //   if(!user?.verifytoken) return;
  //   const userdata = {
  //     token: user?.verifytoken,
  //     filter: {
  //       username: user?.details?.username,
  //       // status: "MATCHED",
  //       deleted: false,
  //       result: 'ACTIVE',
  //     },
  //     sort: { placedTime: -1 },
  //   };

  //   if(history.location.pathname.includes('event-page') && setThisEventDispatch) {
  //     const parts = history?.location?.pathname.split('/');
  //     const lastParameter = parts[parts.length - 1];
  //     userdata.filter['eventId'] = lastParameter;
  //   }else {
  //     userdata.filter['status'] = 'MATCHED';
  //   }

  //   Socket.emit('get-bets', userdata);
  // }

  // useEffect(() => {
  //   if(width>=1024) {
  //     Socket.on('get-bets-success', getBetsSuccess)
  //   }
    
  //   return () => {
  //     if(width>=1024) {
  //       Socket.off('get-bets-success', getBetsSuccess)
  //       // actions.getAllEventsOpenBetsError();
  //       // actions.getEventPageOpenBetsError();
  //     }
  //   }
  // }, [Socket, history.location, setThisEventDispatch]);


  // const getBetsSuccess = (...args) => {
  //   if(Array.isArray(args[0])) {
  //     if(history.location.pathname.includes('event-page') && setThisEventDispatch) actions.getEventPageOpenBetsSuccess(args[0]);
  //     else actions.getAllEventsOpenBetsSuccess(args[0]);
  //   }
  // }


  useEffect(() => {
    if(
      history.location.pathname.includes('event-page') ||
      history.location.pathname.includes('virtual-event-page')
    ) {
      actions.setThisEvent(true);
    }else {
      actions.setThisEvent(false);
    }
  }, [history.location]);


  return (
    <>
      <div className="betSlipDesktop">
        <div className="betSlipDesktop__topSection">
          <span className="betSlipDesktop__topSection-title">Betslip</span>
        </div>
        <div className="betSlipDesktop__betTop">
          <div style={{display: 'flex'}}>
          <div
            className={
              isOpenBets
                ? 'betSlipDesktop__betTop-slip'
                : 'betSlipDesktop__betTop-slip active'
            }
            onClick={() => setIsOpenBets(false)}
          >
            Betslip
          </div>
          <div
            className={
              !isOpenBets
                ? 'betSlipDesktop__betTop-openBets'
                : 'betSlipDesktop__betTop-openBets active'
            }
            onClick={() => setIsOpenBets(true)}
          >
            Open Bets
          </div>
          </div>
          <button
            type="button"
            onClick={() => {
              if(!auth) actions.setLoginNew(true)
              else actions.setIsOpen(!isPopUpOpenDispatch)
            }}
            className="betSlipDesktop__betTop-editStakesBtn"
          >Edit Stakes
          </button>
        </div>


        <div className="betSlipDesktop__betMain">
          {isOpenBets ? (
            <>
              <div className="betSlipDesktop__topFilters">
                <div
                  className="betSlipDesktop__topFilters-item"
                  onClick={() => {
                    // setIsBetInfo(!isBetInfo);
                    // console.log(isBetInfo);
                    // dispatch(setInfoDetailed(!setInfoDispatch))
                    actions.setInfoDetailed(!setInfoDispatch)
                  }}
                >
                  <div
                    className={
                      !setInfoDispatch
                        ? 'betSlipDesktop__topFilters-item-check'
                        : 'betSlipDesktop__topFilters-item-check checked'
                    }
                  ></div>
                  <span className="betSlipDesktop__topFilters-item-title">
                    Show bet Info
                  </span>
                </div>
                  <div
                    className="betSlipDesktop__topFilters-item"
                    onClick={() => {
                      actions.setThisEvent(!setThisEventDispatch)
                      // requestUnsettledBets();
                    }}
                  >
                    <div
                      className={
                        !setThisEventDispatch
                          ? 'betSlipDesktop__topFilters-item-check'
                          : 'betSlipDesktop__topFilters-item-check checked'
                      }
                    ></div>
                    <span className="betSlipDesktop__topFilters-item-title">
                      Show bets for this event
                    </span>
                  </div>
              </div>
              
              {/* <OpenBets openBets={eventOpenBets?.length>0? eventOpenBets : allEventsOpenBets} isEvent={true} redirect={true} /> */}
              <OpenBets openBets={!!setThisEventDispatch? eventOpenBets : allEventsOpenBets} isEvent={true} redirect={true} />
              {/* {(false)&&
              } */}
            </>
          ) : (
            <>
            {betPlacing.showBetPlacingPopover && <div className='betSlipDesktop__betMain-shadow'></div>}
            <DesktopBetPlacing />
            </>
          )}
        </div>
      </div>
      {/* <EditStakesPopUp open={isPopUpOpenDispatch} /> */}
    </>
  )
}

export default connectBetSlipDesktop()(BetSlipDesktop)
