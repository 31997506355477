import React from 'react'
import SportButton from './SportButton/SportButton'


const SportsButtons = ({ sportsButtons }) => {
  const sportsButtonsSortForIndex = [...sportsButtons].sort((a, b) => a.index > b.index ? 1 : -1);

  return (
    <div className="sport-buttons" >
      {
        sportsButtonsSortForIndex.map((itemButton) => {
          return (
            <SportButton key={itemButton.index} itemButton={itemButton}/>
          )
        })
      }
    </div>
  )
}

export default SportsButtons
