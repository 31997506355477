import { createSlice } from '@reduxjs/toolkit'

export const LoginNavigationSlice = createSlice({
  name: 'loginNavigation',
  initialState: {
    navgationPage: '',
    idArray: [],
    navigationId: '',
    navigationName: '',
    casinoType: '',   //only when casino provider api loading on default page
    selectedCasinoProvider: '',   //only when casino provider api loading on default page
  },
  reducers: {
    setLoginNavigationData: (state, action) => {
        const { page, ids, navigationId, navigationName, casinoType, selectedCasinoProvider } = action.payload;

        if (page !== undefined) state.navgationPage = page;
        if (ids !== undefined) state.idArray = ids;
        if (navigationId !== undefined) state.navigationId = navigationId;
        if (navigationName !== undefined) state.navigationName = navigationName;
        if (casinoType !== undefined) state.casinoType = casinoType;
        if (selectedCasinoProvider !== undefined) state.selectedCasinoProvider = selectedCasinoProvider;
    },

    clearNavigationData: (state) => {
        state.navgationPage = '';
        state.idArray = [];
        state.navigationId = '';
        state.navigationName = '';
        state.casinoType = '';
        state.selectedCasinoProvider = '';
    },
  },
})

export const { setLoginNavigationData, clearNavigationData } = LoginNavigationSlice.actions

export default LoginNavigationSlice.reducer
