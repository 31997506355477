import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButton,
  IonIcon,
  useIonViewDidEnter,
  useIonViewWillLeave,
  withIonLifeCycle
} from "@ionic/react";
import { connectCasinoLive } from "./CasinoLive.connect";
import { useHistory, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import goBackIcon from "../../assets/images/goback.svg";
// import { getCasinoLiveSuccess } from "../../store/casinoLive/casinoLive.slice";
// import { useDispatch } from "react-redux";
import userIcon from '../../assets/images/toolbar-user-icon.svg';
import walletIcon from '../../assets/images/wallet.svg';
import SlotsCasinoPopUp from "../../components/SlotsCasinoPopup";


const CasinoLive = ({ casinoLive, user, balance, slotCasinoPopUp, actions, elementsParams }) => {
  const history = useHistory();
  const { gameId, tableId } = useParams();
  // const [showTransferBtn, setShowTransferBtn] = useState(false);
  const refIframe = useRef(null);
  const [currentCasinoId, setCurrentCasinoId] = useState();
  const [requestSent, setRequestSent] = useState(false);
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth;

  useEffect(() => {
    if(!user?.verifytoken) {
      history.push("/default-page");
    }
  }, [user])

  useIonViewDidEnter(() => {
      initiateRequest(gameId);
  }, [gameId]);

  // console.log("Casino Live: ", casinoLive);


  useIonViewWillLeave(() => {
    // console.log("useIonViewWillLeave");
    casinoLive = undefined;
    setRequestSent(false);
    setCurrentCasinoId(0);
    // console.log("!!End Casino");
    actions.requestCasinoEnd();
  });

  const initiateRequest = (gameId) => {
      setCurrentCasinoId(gameId);
      if (!casinoLive && gameId !== currentCasinoId && !requestSent) {
        // console.log(gameId);
        setRequestSent(true);
        actions.requestCasinoStart(gameId, tableId || '');
        setRequestSent(false);
      }
  }

  const handleMessage = (event) => {
    if (typeof event.data == "string") {
      if (event.data.indexOf("error:") === 0) {
        toastr.error("", event.data.slice(7));
      }
      if (event.data.indexOf("success:") === 0) {
        toastr.success("", event.data.slice(8));
      }
    }
  };
  useEffect(() => {
    window.addEventListener("message", handleMessage);
    if (width >= 1024 && history.location.pathname.includes("/casino_live")) initiateRequest(gameId);
    return () => {
      window.removeEventListener("message", handleMessage);
      if (width >= 1024 && !history.location.pathname.includes("/casino_live")) {
        // console.log("!!End Casino");
        actions.requestCasinoEnd();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const endCasino = () => {
    history.goBack();
    // history.push("/default-page");
  };

  useEffect(() => {

  }, []);

  // const handleLocalhost = (src) => src.replace(/localhost:3000/, 'open.gemexch.bet')
  //const handleLocalhost = (src) => (window.location.host == "localhost:3000") ? src.replace(/https:\/\/route2222.com/, 'http://localhost:4200') : src

  //TODO: remove before pod
  // const handleLocalhost = (src) => src.replace(/casino-landing.route2222.com/, 'react.d1vvxfivfbj43s.amplifyapp.com')


  // const toggleTF = () => {
  //   if (refIframe.current) {
  //     refIframe.current.contentWindow.postMessage('toggleTF', '*');
  //   }
  // }

  return (width >= 1024 ? (
      <IonPage className="CasinoGames" style={width >= 1024 ? { overflow: `hidden!important` } : {}}>
        {/* <div style={{ height: "135px" }}></div> */}
        <IonHeader>
          <IonToolbar className="CasinoGames__toolbar">
            <div className="CasinoGames__toolbar__wrapper">
              <div className="ion-toolbar__left">
                <IonButton
                  className="menu-btn"
                  mode="md"
                  fill="clear"
                  onClick={endCasino}
                >
                  {/* <IonIcon className="menu-icon go-back-icon" /> */}
                  <IonIcon className="menu-icon go-back-icon" src={goBackIcon} />
                </IonButton>
                {/* <div className="CasinoGames__toolbar__title">Exit Casino</div> */}
                <div className="headerDesktop-content__logo-img" alt="" />
              </div>

              <div className="ion-toolbar__left" style={{gap: '10px', marginRight: '20px'}}>
                {(!!user && !!user?.details)&&
                  <div className="ion-toolbar__left">
                    <IonIcon
                      className="menu-acc-icon"
                      slot="start"
                      icon={userIcon}
                    />
                    <p style={{marginLeft: '5px'}}>{user?.details?.username}</p>

                  </div>
                }

                {(!!balance)&&
                  <div className="ion-toolbar__left">
                    <IonIcon
                      className="menu-acc-icon"
                      slot="start"
                      icon={walletIcon}
                    />
                    <p style={{marginLeft: '5px'}}>{balance?.balance || 0}</p>

                  </div>
                }
              </div>
              {/* {showTransferBtn && <div className="ion-toolbar__right">
              <div className="CasinoGames__toolbar__title CasinoGames__toolbar__title--btn" onClick={toggleTF}>Transfer Funds <span className="CasinoGames__toolbar__title--btn__plus">+</span></div>
            </div>} */}
            </div>
          </IonToolbar>
        </IonHeader>
        <div>
          {casinoLive && casinoLive.url && <iframe
            className="CasinoGames__content__video"
            ref={refIframe}
            allowFullScreen
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            // scrolling="yes"
            title="casino live"
            src={casinoLive.url}>
            {/* src={handleLocalhost(casinoLive.url)}> */}
          </iframe>}
          {/*<FooterDesktop />*/}
        </div>

        {slotCasinoPopUp?.isOpen && <SlotsCasinoPopUp/>}
      </IonPage>
    ) : (
      <IonPage className="CasinoGames" style={width >= 1024 ? { paddingTop: `135px` } : {}}>
        <IonHeader>
          <IonToolbar className="CasinoGames__toolbar">
            <div className="CasinoGames__toolbar__wrapper" style={{alignItems: 'center'}}>
              <div className="ion-toolbar__left">
                <IonButton
                  className="menu-btn"
                  mode="md"
                  fill="clear"
                  onClick={endCasino}
                >
                  {/* <IonIcon className="menu-icon go-back-icon" /> */}
                  <IonIcon className="menu-icon go-back-icon" src={goBackIcon} />
                </IonButton>
                {/* <div className="CasinoGames__toolbar__title">Exit Casino</div> */}
                <div className="toolbar-logo" alt="" />
              </div>

              <div className="ion-toolbar__left" style={{display: 'block', marginRight: '10px'}}>
                {(!!user && !!user?.details)&&
                  <div className="ion-toolbar__left">
                    <IonIcon
                      className="menu-acc-icon"
                      slot="start"
                      icon={userIcon}
                    />
                    <p style={{margin: '0 0 0 5px', fontSize: 14}}>{user?.details?.username}</p>

                  </div>
                }

                {(!!balance)&&
                  <div className="ion-toolbar__left">
                    <IonIcon
                      className="menu-acc-icon"
                      slot="start"
                      icon={walletIcon}
                    />
                    <p style={{margin: '5px 0 0 5px', fontSize: 14}}>{balance?.balance || 0}</p>

                  </div>
                }
              </div>
              {/* {showTransferBtn && <div className="ion-toolbar__right">
              <div className="CasinoGames__toolbar__title CasinoGames__toolbar__title--btn" onClick={toggleTF}>Transfer Funds <span className="CasinoGames__toolbar__title--btn__plus">+</span></div>
            </div>} */}
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          {casinoLive && casinoLive.url && <iframe
            className="CasinoGames__content__video"
            ref={refIframe}
            allowFullScreen
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            // scrolling="yes"
            title="casino live"
            src={casinoLive.url}>
            {/* src={handleLocalhost(casinoLive.url)}> */}
          </iframe>}
          {/*<FooterDesktop />*/}
        </IonContent>
        {slotCasinoPopUp?.isOpen && <SlotsCasinoPopUp/>}
      </IonPage>
    )
  );
};

const DifferentWarningComponent = () => <div style={{ color: "#000000" }}>NO WAY!</div>;
export default withIonLifeCycle(connectCasinoLive()(CasinoLive));
