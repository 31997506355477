import React, { useEffect, useMemo, useRef, useState } from 'react';
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/fixed";
import { useIonViewWillEnter, IonContent, IonHeader, IonLabel, IonPage, IonImg } from '@ionic/react';
import './VirtualEventPage.scss';
import '../../components/SportEvent/SportEvent.scss';
import { connectVirtualEventPage } from './virtualeventpage.connect';
import Toolbar from '../../components/Toolbar/Toolbar';
import { useHistory, useParams } from 'react-router-dom';
import { Socket } from '../../services/socket';
import VirtualLiveSection from '../../components/Virtual/VirtualLiveSection/VirtualLiveSection';
import VirtualScoreCard from '../../components/Virtual/VirtualScoreCard/VirtualScoreCard';
import LoaderContent from '../../components/LoaderContent/LoaderContent';
import VirtualMarketStandard from '../../components/Virtual/VirtualMarketStandard/VirtualMarketStandard';
import OpenBets from '../../components/OpenBets/OpenBets';
import VirtualLastResults from '../../components/Virtual/VirtualLastResult/VirtualLastResults';
import BallByBallMarketStandard from '../../components/Virtual/BallByBallMarketStandard/BallByBallMarketStandard';
import { formatTime } from '../../helpers/date.helper';
import broadcastImg from '../../assets/images/inplay-logo-iconSport.svg'
import BallByBallPopupModal from '../../components/Virtual/BallByBallPopupModal/BallByBallPopupModal';


const VirtualEventPage = ({
    actions,
    virtualData,
    ballByBallData,
    eventOpenBets,
    virtualLastResults,
    ballByBallLastResults,
    sportData,
    auth,
    elementsParams,
    user,
    timeZoneName
}) => {
    const [contentScrolled, setContentScrolled] = useState(false);
    const [style, setStyle] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [bbbResultModal, setBBBResultModal] = useState(false);
    const [selectedBBBResult, setSelectedBBBResult] = useState(null);
    const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
    const history = useHistory()
    const { eventTypeId } = useParams();
    const virtualEventId = "1234822733";
    const ballByBallEventId = "1122334455";
    const scrollRef = useRef(null);

    useEffect(() => {
        if(!user?.verifytoken) {
            history.push("/default-page");
        }
    }, [user])

    useEffect(() => {
        Socket.on(`virtual-pulse-${virtualEventId}`, handleVirtualPulse);
        Socket.on(`ballbyball-pulse-${ballByBallEventId}`, handleVirtualPulse);
        Socket.on("get-virtual-result-success", handleVirtualResultSuccess);
        Socket.on(`get-ballbyball-result-success`, handleVirtualResultSuccess);
        Socket.on("get-bets-success", getBetsSuccess);
        Socket.on("disconnect", addToRoom);
        
        return () => {
            removeFromRoom();
            Socket.off(`virtual-pulse-${virtualEventId}`, handleVirtualPulse);
            Socket.off(`ballbyball-pulse-${ballByBallEventId}`, handleVirtualPulse);
            Socket.off("get-virtual-result-success", handleVirtualResultSuccess);
            Socket.off(`get-ballbyball-result-success`, handleVirtualResultSuccess);
            Socket.off("get-bets-success", getBetsSuccess);
            Socket.off("disconnect", addToRoom);
            
            actions.getVirtualDataError();
            actions.getBallByBallDataError();
            actions.getEventPageOpenBetsError();
            actions.getVirtualLastResultsError();
            actions.getBallByBallLastResultsError();
        }

    }, [])


    useEffect(() => {
        if(!user?.verifytoken) return;

        addToRoom();
        requestVirtualResult();
        // requestBets();

    }, []);

    const addToRoom = () => {
        if(!user?.verifytoken) return;
        if(eventTypeId == 'v9') {
            const data = {
                token: user?.verifytoken,
                eventId: virtualEventId,
            };
            Socket.emit("add-to-room-virtual", data)
        }else{
            const data = {
                token: user?.verifytoken,
                eventId: ballByBallEventId,
            };
            Socket.emit("add-to-room-ballbyball", data)
        }
    }
    const removeFromRoom = () => {
        if(!user?.verifytoken) return;
        if(eventTypeId == 'v9') {
            const data = {
                token: user?.verifytoken,
                eventId: virtualEventId,
            };
            Socket.emit("remove-from-room-virtual", data)
        }else{
            const data = {
                token: user?.verifytoken,
                eventId: ballByBallEventId,
            };
            Socket.emit("remove-from-room-ballbyball", data)
        }
    }

    const handleVirtualPulse = (...args) => {
        if(eventTypeId == 'v9') {
            actions.getVirtualDataSuccess(args[0]);
        }else{
            actions.getBallByBallDataSuccess(args[0]);
        }

        if(!isLoaded) {
            setLoaded(true);
        }
    }

    const requestVirtualResult = () => {
        if(!user?.verifytoken) return;
        const data = {
            token: user?.verifytoken,
            eventTypeId: eventTypeId,
        };
      
        if(eventTypeId == 'v9') Socket.emit("get-virtual-result", data);
        else Socket.emit("get-ballbyball-result", data);
    }


    const handleVirtualResultSuccess = (...args) => {
        if(eventTypeId == 'v9') {
            actions.getVirtualLastResultsSuccess(args[0]);
        }else {
            actions.getBallByBallLastResultsSuccess(args[0]);
        }
    }

    // const requestBets = () => {
    //     if(!user?.verifytoken) return;
    //     const data = {
    //         token: user?.verifytoken,
    //         filter: {
    //             eventId: eventTypeId=='v9'? virtualEventId : ballByBallEventId,
    //             username: user?.details?.username,
    //             deleted: false,
    //             result: "ACTIVE",
    //         },
    //         sort: { placedTime: -1 },
    //     };
    //     Socket.emit("get-bets", data);
    // }

    const getBetsSuccess = (...args) => {
        const eventId = eventTypeId == 'v9'? virtualEventId : ballByBallEventId;
        let allBets = args[0].filter((item) => {
            return (item.eventId == eventId);
        });
        actions.getEventPageOpenBetsSuccess(allBets);
    }


    const virtualTabs = [
        {
          label: 'market',
          setTab: () => setActiveTab(0),
        },
        {
          label: `open bets (${(eventOpenBets && eventOpenBets?.length) || '0'})`,
          setTab: () => setActiveTab(1),
        },
    ]

    const onScroll = (e) => {
        if (e.detail.deltaY < 0 || e.detail.scrollTop <= 120) {
          if (contentScrolled) setContentScrolled(false)
        } else if (e.detail.deltaY > 0) {
          if (!contentScrolled && e.detail.scrollTop > 120) setContentScrolled(true)
        }
    }

    useEffect(() => {
        if(scrollRef && scrollRef.current) {
          scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [])


    const getZonedTime = (timezone, time) => {
        let date = time ? new Date(time) : new Date()
        if (timezone === 'system')
          date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
        if (timezone === 'india')
          date.setMinutes(date.getMinutes() + date.getTimezoneOffset() + 330)
    
        return date
    }
    const tsToDate = (ts, timezone) => {
        const date = getZonedTime(timezone, ts)
    
        return `${
          date.getDate().toString().length === 1
            ? '0' + date.getDate()
            : date.getDate()
        }/${
          (date.getMonth() + 1).toString().length === 1
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1
        }/${date.getFullYear()} ${date.getHours()}:${formatTime(
          date.getMinutes()
        )}:${formatTime(date.getSeconds())}`
    }


    const onSelectResult = (data) => {
        console.log("My Result: ", data);
        setBBBResultModal(true);
        setSelectedBBBResult(data);
    }


    return (
        <>
            {width<1024?
                <IonPage>
                    <IonHeader className="onscrollHeader" style={style}>
                        <Toolbar
                            contentScrolled={contentScrolled}
                            tabs={virtualTabs}
                            activeTab={activeTab}
                            eventHeader={isLoaded}
                            setStyle={setStyle}
                        />
                    </IonHeader>

                    <IonContent
                        className="EventPage"
                        fullscreen
                        onIonScroll={onScroll}
                        scrollEvents={true}
                    >
                        {!isLoaded&&  <LoaderContent />}

                        {activeTab == 0&&
                            <>
                                {(user?.verifytoken && isLoaded)&&
                                    <VirtualLiveSection 
                                        videoUrl={eventTypeId=='v9'?
                                            'https://kushubmedia.com/diamondvirtual/cricket.html'
                                            :
                                            ballByBallData?.ballvideo
                                        }
                                        eventTypeId={eventTypeId}
                                        data={ballByBallData}
                                    />
                                }
                                

                                {(eventTypeId == 'v9' && !!virtualData)&&
                                    <>
                                        <VirtualScoreCard 
                                            virtualData={virtualData}
                                        />

                                        <div className='SportEvent' style={{padding: '0 12px'}}>
                                            <VirtualMarketStandard 
                                                data={virtualData}
                                                eventOpenBets={eventOpenBets}
                                            />
                                        </div>

                                        {virtualLastResults?.length>0&&
                                            <VirtualLastResults 
                                                data={virtualLastResults} 
                                                eventTypeId={eventTypeId} 
                                                onSelectResult={(val)=>{onSelectResult(val);}}
                                            />
                                        }
                                    </>
                                }

                                {(eventTypeId == 'b9' && !!ballByBallData)&&
                                    <>
                                        <div className='SportEvent' style={{padding: '0 12px'}}>
                                            <BallByBallMarketStandard 
                                                data={ballByBallData}
                                                eventOpenBets={eventOpenBets}
                                            />
                                        </div>

                                        {ballByBallLastResults?.length>0&&
                                            <VirtualLastResults 
                                                data={ballByBallLastResults} 
                                                eventTypeId={eventTypeId} 
                                                onSelectResult={(val)=>{onSelectResult(val);}}
                                            />
                                        }
                                    </>
                                }


                            </>
                        }

                        {(activeTab == 1)&& <OpenBets openBets={eventOpenBets} isEvent={true} />}

                        {(eventTypeId == 'b9' && bbbResultModal && selectedBBBResult)&&
                            <div>
                                <BallByBallPopupModal 
                                    handleClose={()=>{setBBBResultModal(false); setSelectedBBBResult(null);}}
                                    data={selectedBBBResult}
                                />
                            </div>
                        }
                    </IonContent>
                </IonPage>
                :
                <div
                    className="EventPage"
                    fullscreen
                    ref={scrollRef}
                >

                    {(isLoaded)?
                        <>
                            <div
                                style={{ marginBottom: '15px' }}
                                className={`favourites-header withPlay`}
                            >
                                <div className={`favourites-header__inner-wrapper`}>
                                    <IonImg
                                        className="inplay-item__broadcast-icon"
                                        src={broadcastImg}
                                    />
                                    <h1 style={{ marginLeft: '12px' }}>
                                        {eventTypeId=='v9'? (`Virtual - ${virtualData?.eventName}` || 'Virtual') : (ballByBallData?.eventName || 'Ball By Ball')}
                                    </h1>
                                    <div className="sportsPage-headerBet">
                                        <span>{tsToDate(eventTypeId=='v9'? virtualData?.openDate : ballByBallData?.openDate, timeZoneName).slice(0, 10)}</span>&nbsp;
                                        <span>{tsToDate(eventTypeId=='v9'? virtualData?.openDate : ballByBallData?.openDate, timeZoneName).slice(11, 20).split(':', 2).join(':')}</span>
                                    </div>
                                </div>
                            </div>

                            <VirtualLiveSection 
                                videoUrl={eventTypeId=='v9'?
                                    'https://kushubmedia.com/diamondvirtual/cricket.html'
                                    :
                                    ballByBallData?.ballvideo
                                }
                                eventTypeId={eventTypeId}
                                data={ballByBallData}
                            />

                            {(eventTypeId == 'v9' && !!virtualData)&&
                                <>
                                    <VirtualScoreCard 
                                        virtualData={virtualData}
                                    />

                                    <div className='SportEvent' style={{padding: '0 12px'}}>
                                        <VirtualMarketStandard 
                                            data={virtualData}
                                            eventOpenBets={eventOpenBets}
                                        />
                                    </div>

                                    {virtualLastResults?.length>0&&
                                        <VirtualLastResults 
                                            data={virtualLastResults} 
                                            eventTypeId={eventTypeId} 
                                            onSelectResult={(val)=>{onSelectResult(val);}}
                                        />
                                    }
                                </>
                            }

                            {(eventTypeId == 'b9' && !!ballByBallData)&&
                                <>
                                    <div className='SportEvent' style={{padding: '0 12px'}}>
                                        <BallByBallMarketStandard 
                                            data={ballByBallData}
                                            eventOpenBets={eventOpenBets}
                                        />
                                    </div>

                                    {ballByBallLastResults?.length>0&&
                                        <VirtualLastResults 
                                            data={ballByBallLastResults} 
                                            eventTypeId={eventTypeId} 
                                            onSelectResult={(val)=>{onSelectResult(val);}}
                                        />
                                    }
                                </>
                            }
                        </>
                        :
                        <LoaderContent />
                    }


                    {(eventTypeId == 'b9' && bbbResultModal && selectedBBBResult)&&
                        <BallByBallPopupModal 
                            handleClose={()=>{setBBBResultModal(false); setSelectedBBBResult(null);}}
                            data={selectedBBBResult}
                        />
                    }
                </div>
            }
        </>
    )
}

export default connectVirtualEventPage()(VirtualEventPage)