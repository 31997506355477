import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import './casinoGridList.scss'
import { IonList, IonListHeader } from "@ionic/react";
import { useHistory } from 'react-router';
import { headerType } from '../../helpers/InPlayPopular.helper';
import EventTypeBanners from '../EventTypeBanners/EventTypeBanners';
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'
import { Socket } from '../../services/socket';
import { requestLiveCasinoLobby } from '../../store/liveCasino/liveCasino.thunks';
import { BASE_DEV_URL } from '../../config/api.config';
import axios from 'axios';
import { setIsNavigationID, setIsNavigationName } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice';
import { setCasinoType, setSelectedProvider } from '../../store/casinoprovider/casinoprovider.slice';
import { setLoginNavigationData } from '../../store/loginNavigation/LoginNavigation.slice';

const CasinoGridList = ({ itemHeaderData, itemsData, type }) => {
    const history = useHistory();
    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const getBanner = () => {
        if (headerType['INPLAY'] == type) {
             return <EventTypeBanners eventType={itemHeaderData.id} />
        } else return null
    }

    const renderHeader = () => {
        return(
            <IonListHeader 
                className="inplay-item-list-casino__header"
                style={{backgroundColor: '#ff4500', color: '#fff'}}
            >
                <div className="iinplay-item-list-casino__header-content">
                    <div className="inplay-item-list-casino__header-logo">
                        <i className={`inplay-content__logo-icon inplay-content__logo-icon--casino inplay-color-icon`} />
                        {itemHeaderData.name}
                    </div>
                </div>
            </IonListHeader>
        );
    }


    

    return (
        <IonList className="inplay-item-list_casino">
            {getBanner()}
            {renderHeader()}
            <div className='casino-content'>
                {itemsData && itemsData.map((item, index) => {
                    const gotoEvent = () => {
                        if(auth) {
                            // if (item.eventTypeId == '77777')  {
                            // if (item.eventTypeId == '99999')  {
                            //     history.push(`/casino_live/${item?.eventId}/${item?.eventType}`)
                            // } else {
                            //     history.push(`/event-page/${item?.eventTypeId}/${item?.eventId}`)
                            // }

                            if (item.eventTypeId == '99999') {
                                if(item.name) {
                                    dispatch(setIsNavigationID('99999'))
                                    dispatch(setIsNavigationName('Casino'));
                                    dispatch(setCasinoType('Int Casino'));
                                    dispatch(setSelectedProvider(item?.name));
                                }else{
                                    history.push(`/livecasino-lobby/LIVECASINO`)
                                }
                            }

                        } else {
                            dispatch(setLoginNew(true));
                            dispatch(setLoginNavigationData({ navigationId: '99999', navigationName: 'Casino', casinoType: 'Int Casino', selectedCasinoProvider: item?.name }));
                        }
                    }
                    return (
                        <div className='casino-item' key={index} onClick={gotoEvent} style={{backgroundColor: `${item?.backgroundColour}`}}>
                            <div className="casino-img-body" style={{
                                backgroundImage: `url(${item?.image || itemHeaderData?.banner})`
                            }}>
                            </div>
                        </div>
                    )
                })}

            </div>
        </IonList>

    )
}

export default CasinoGridList
