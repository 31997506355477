import httpAuth from '../../services/httpAuth'

import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getPromotionsError, getPromotionsSuccess } from './promotions.slice'
import NewHttpAuth from '../../services/NewHttpAuth'

export const requestPromotions = () => async (dispatch) => {
    try {
        const response = await NewHttpAuth.get(`/get-banner`)
        // const response = await axios.get(`https://lotus.com/banners`)
        // console.log("Get Banners: ", response);
        // if (response.data.success) {
        if (!response.data.error) {
            // console.log("res: ", response.data.response)
            // console.log("response.data.result", response.data.result)
            dispatch(getPromotionsSuccess(response.data.response))
        }
        return response
    } catch (e) {
        dispatch(getPromotionsError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
    }
}

