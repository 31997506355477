import React from "react"

function formatNumberInIndianSystem(num) {
    if (num < 1000) return num.toString();
  
    const suffixes = [
      { value: 1e7, symbol: 'Cr' }, // Crore
      { value: 1e5, symbol: 'L' },  // Lakh
      { value: 1e3, symbol: 'k' }   // Thousand
    ];
  
    for (let i = 0; i < suffixes.length; i++) {
      if (num >= suffixes[i].value) {
        const shortValue = (num / suffixes[i].value).toFixed(1); // Keep one decimal place
        // Remove unnecessary '.0' part
        return shortValue.replace(/\.0$/, '') + suffixes[i].symbol;
      }
    }
  
    return num.toString();
}


export const minmaxformatter = (minMaxAll, eventTypeId, marketType) => {
    if(eventTypeId == 4 && marketType=='SESSION') {
        return <b>Min: <span>{formatNumberInIndianSystem(minMaxAll?.fancyMinLimit || 0)}</span> Max: <span>{formatNumberInIndianSystem(minMaxAll?.fancyMaxLimit || 0)}</span></b>;
    }else if(eventTypeId == 4 && marketType=='Special') {
        return <b>Min: <span>{formatNumberInIndianSystem(minMaxAll?.bookmakerMinLimit || 0)}</span> Max: <span>{formatNumberInIndianSystem(minMaxAll?.bookmakerMaxLimit || 0)}</span></b>;
    }else if(eventTypeId == 4 && marketType=='MATCH_ODDS') {
        return <b>Min: <span>{formatNumberInIndianSystem(minMaxAll?.oddsMinLimit || 0)}</span> Max: <span>{formatNumberInIndianSystem(minMaxAll?.oddsMaxLimit || 0)}</span></b>;
    }else if(eventTypeId == 2 && marketType=='SESSION') {
        return <b>Min: <span>{formatNumberInIndianSystem(minMaxAll?.tfancyMinLimit || 0)}</span> Max: <span>{formatNumberInIndianSystem(minMaxAll?.tfancyMaxLimit || 0)}</span></b>;
    }else if(eventTypeId == 2 && marketType=='Special') {
        return <b>Min: <span>{formatNumberInIndianSystem(minMaxAll?.tbookmakerMinLimit || 0)}</span> Max: <span>{formatNumberInIndianSystem(minMaxAll?.tbookmakerMaxLimit || 0)}</span></b>;
    }else if(eventTypeId == 2 && marketType=='MATCH_ODDS') {
        return <b>Min: <span>{formatNumberInIndianSystem(minMaxAll?.toddsMinLimit || 0)}</span> Max: <span>{formatNumberInIndianSystem(minMaxAll?.toddsMaxLimit || 0)}</span></b>;
    }else if(eventTypeId == 1 && marketType=='SESSION') {
        return <b>Min: <span>{formatNumberInIndianSystem(minMaxAll?.sfancyMinLimit || 0)}</span> Max: <span>{formatNumberInIndianSystem(minMaxAll?.sfancyMaxLimit || 0)}</span></b>;
    }else if(eventTypeId == 1 && marketType=='Special') {
        return <b>Min: <span>{formatNumberInIndianSystem(minMaxAll?.sbookmakerMinLimit || 0)}</span> Max: <span>{formatNumberInIndianSystem(minMaxAll?.sbookmakerMaxLimit || 0)}</span></b>;
    }else {
        return <b>Min: <span>{formatNumberInIndianSystem(minMaxAll?.soddsMinLimit || 0)}</span> Max: <span>{formatNumberInIndianSystem(minMaxAll?.soddsMaxLimit || 0)}</span></b>;
    }
}