import React, { useEffect, useState, useRef } from 'react'
import { IonContent, IonHeader, IonItemGroup, IonPage } from '@ionic/react'
import {
  clearIntervalAsync,
  setIntervalAsync,
} from 'set-interval-async/dynamic'
import './defaultpage.scss'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../config/app.config'
import { connectDefaultPage } from './defaultpage.connect'
import Toolbar from '../../components/Toolbar/Toolbar'
import ToolbarDesktop from '../../components/ToolbarDesktop/ToolbarDesktop'
import Promotion from '../../components/Promotion/promotion'
import InPlayPopular from '../../components/InPlay/inPlayPopular'
import { headerType } from '../../helpers/InPlayPopular.helper'
import LegalAgePopover from '../../components/LegalAgePopover/LegalAgePopover'
import KycPopover from '../../components/KycPopover/KycPopover'
import MoreRulesPopover from '../../components/MoreRulesPopover/MoreRulesPopover'
import { useHistory } from 'react-router'
import LoaderContent from '../../components/LoaderContent/LoaderContent'
import BannerPopUp from '../../components/BannerPopUp/BannerPopUp'
import { useDispatch, useSelector } from 'react-redux'
import LeftSideMenuDesktop from '../../components/LeftSideMenuDesktop/LeftSideMenuDesktop'
import BalanceInformation from '../../components/BalanceInformation/BalanceInformation'
import BetSettingsDesktop from '../../components/BetSettingsDesktop/BetSettingsDesktop'
import BetSlipDesktop from '../../components/BetSlipDesktop/BetSlipDesktop'
import BetPlacing from '../../components/BetPlacing/BetPlacing'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
import whatsAppImg from '../../assets/images/whatsApp.svg'
import WhatsappBanner from '../WhatsappBanner/WhatsappBanner'
import { isSignedUpWithNumberInBranchHelper } from '../../helpers/isSignedUpWithNumberInBranch.helper'
import Bonuses from '../bonuses/Bonuses'
import BonusBanner from '../bonuses/BonusBanner'
import BonusCard from '../../components/BonusCard/BonusCard'
import { requestFortuneWheel } from '../../store/fortuneWheel/fortuneWheel.thunks'
import Verification from "../../components/Verification/Verification";
import VerifButtons from '../../components/Verification/VerifButtons'
import moment from 'moment'
import { ExchangeGamesData } from '../../constants/Casino/ExchangeGamesData'
import ExchangeGamesGridList from '../../components/ExchangeGamesGridList/ExchangeGamesGridList'
import leftSideMenuMapper from '../../helpers/getLeftSideMenuMapper.helper'
import PopularGamesGridList from '../../components/PopularGamesGridList/PopularGamesGridList'
import { PopularCasinoGamesData } from '../../constants/Casino/PopularCasinoGamesData'
import { CasinoProviderDATA } from '../../constants/Casino/CasinoProviderData'
import CasinoGridList from '../../components/CasinoGridList/CasinoGridList'
import CasinoProvider from '../../components/CasinoProvider/CasinoProvider'
import { Socket } from '../../services/socket'
import { getFreeHomeAllMarketsSuccess, getFreeHomeAllMarketsError } from '../../store/homeMarketEvents/homeMarketEvents.slice'
import { VirtualData, BallByBallData } from '../../constants/InplayVirtualObjects'


const DefaultPage = ({
  auth,
  lastActivityDate,
  inplayPopular,
  promotions,
  balanceSettings,
  legalAgePopoverShow,
  kycPopoverShow,
  moreRulesPopoverShow,
  timeSettings,
  betPlacing,
  orderCount,
  actions,
  elementsParams,
  fortuneWheel,
  menu,
  homeMarketEvents,
  ...props
}) => {
  const history = useHistory()
  const [displayedBack, setDisplayedBack] = useState(false)
  const bannerOpen = useSelector((state) => state.bannerPopUp)
  const navigationID = useSelector((state) => state.navigationHeaderMenu.navigationID);
  const dispatch = useDispatch()
  const [inplayData, setInplayData] = useState([]);
  const [upcomingData, setUpcomingData] = useState([]); 
  const inplayRef = useRef();

  // let pathname = history.location.pathname
  const pathname = props.localPathnameNow

  // useEffect(() => {
  //   if (history.location.pathname === '/default-page' && auth)
  //     actions.requestOrderCount()
  //   // if (history.location.pathname === '/') actions.requestOrderCount();
  // }, [actions, lastActivityDate])

  const getInplayPopular = () => {
    actions
      .requestInplayPopular()
      .catch((e) => console.log('requestInplayPopular error = ', e))
  }


  useEffect(() => {
    Socket.on("get-freehomeallmarkets-success", GetHomeMarketSuccess);
    // Socket.on('get-bets-success', getOpenBetsSuccess);
    
    return () => {
      Socket.off("get-freehomeallmarkets-success", GetHomeMarketSuccess);
      // Socket.off('get-bets-success', getOpenBetsSuccess);
    }
  }, []);



  const GetHomeMarketSuccess = async(...args) => {
    try {
      const result = args[0].map(innerArray => {
        return innerArray.reduce((acc, item) => {
          const { competitionName } = item;
          const existingIndex = acc.findIndex(obj => obj.competitionName === competitionName);
          
          if (existingIndex === -1) {
            acc.push({
              competitionName,
              data: [item]
            });
          } else {
            acc[existingIndex].data.push(item);
          }
          
          return acc;
        }, []);
      });

      dispatch(getFreeHomeAllMarketsSuccess(result));
    } catch (error) {
      dispatch(getFreeHomeAllMarketsError());
    }
  }

  useEffect(() => {
    if(!homeMarketEvents.freeHomeAllMarkets?.length){
      requestHomeMarkets();
    }

  }, [homeMarketEvents.freeHomeAllMarkets])

  const requestHomeMarkets = () => {
    const senddata = {
      filter: {
        managers: "OSGCLUB",
        eventTypeId: { $nin: ["t9", "4321"] },
        visible: true,
        deleted: false,
        marketType: { $in: ["MATCH_ODDS", "TOURNAMENT_WINNER"] },
        "marketBook.status": { $ne: "CLOSED" },
      },
      sort: { openDate: 1 },
    };
  
    Socket.emit("get-free-home-allmarkets", senddata);
  }



  // const getPromotion = () => {
  // actions.requestPromotions().catch(e => console.log('requestInplayPopular error = ', e))
  // }

  // useEffect(() => {
  // getPromotion()
  //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {
  //   // if (!inplayPopular && history.location.pathname === '/') getInplayPopular()
  //   if (!inplayPopular && history.location.pathname === '/default-page')
  //     getInplayPopular()
  //   else {
  //     const timer = setIntervalAsync(async () => {
  //       // if (history.location.pathname === '/') getInplayPopular()
  //       if (history.location.pathname === '/default-page')
  //         await getInplayPopular()
  //       // else if (inplayPopular) actions.getInplayPopularSuccess(null)
  //     }, GET_INPLAY_POPULAR_INTERVAL)
  //     return () => clearIntervalAsync(timer)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [inplayPopular, history.location.pathname])

  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth

  const [renderFooter, setRenderFooter] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (window.location.pathname.includes('login')) {
        actions.setLoginNew(true)
      }

      setRenderFooter(true)
    }, 1500)
  }, [])

  useEffect(() => {
    if (window?.location?.pathname?.includes('register')) {
      setTimeout(() => {
        // window.history.pushState({},"", "/");
        actions.setLoginNew(true)
        actions.setTab(2)
      }, 500)
    }
  }, []);


  let userData
  useEffect(() => {
    userData = JSON.parse(localStorage.getItem('user_data'))
    if (width >= 1024) {
      window.history.pushState(null, document.title)
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title)
      })
    }
  }, [history.location.pathname])


  useEffect (()=> {
    if (auth) {
      dispatch(requestFortuneWheel('70001', 'FAWK'))
    }
  },[auth])

  useEffect(() => {
    if(navigationID == '4' || navigationID == '2' || navigationID == '1') {
      if (inplayRef.current) {
        setTimeout(() => {
          inplayRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 200)
      }
    }
  }, [navigationID])


  const getMenu = (data) => {
    // console.log("GetMenu: ", data);
    // actions.requestMenu(data).catch((e) => console.log("menu error = ", e));
    actions.requestMenu().catch((e) => console.log("menu error = ", e));
  };


  // useEffect(() => {
  //   if(menu?.length === 0 && width < 1024) {
  //     getMenu();
  //   }
    
  // }, [menu]);


  useEffect(() => {
    if(homeMarketEvents.freeHomeAllMarkets?.length && inplayData?.length === 0 && (width>=1024 || upcomingData?.length === 0) ) {
      const inplay = [];
      const upcoming = [];
      
      const myVirtualData = {...VirtualData};
      myVirtualData.eventName = homeMarketEvents.freeHomeAllMarkets[3][0]? `🎮 Virtual - ${homeMarketEvents.freeHomeAllMarkets[3][0]?.data[0]?.eventName}` : '🎮 Virtual';
      inplay.push(myVirtualData);
      inplay.push(BallByBallData);


      homeMarketEvents.freeHomeAllMarkets.forEach((innerArray, innerIndex) => {
        innerArray.forEach(obj => {
          obj.data.forEach(innerObj => {
            if (
              // innerObj?.marketBook?.status === 'OPEN' || 
              // moment(innerObj.openDate).isBefore(moment()) ||
              // moment(innerObj.openDate).isBetween(moment(), moment().add(15, 'minutes'))
              innerObj?.marketBook?.inplay === true || moment(innerObj.openDate).isBefore(moment()) || width>=1024
            ) {
              inplay.push(innerObj);
            } else {
              upcoming.push(innerObj);
            }
          });
        });
      });

      setInplayData(inplay);
      setUpcomingData(upcoming);
    }
  }, [homeMarketEvents.freeHomeAllMarkets, inplayData, upcomingData, auth])

  
  return (
    <>
      {width < 1024 ? (
        <>
          {/* {bannerOpen && <BannerPopUp />} */}
          <IonPage>
            <IonHeader>
              <Toolbar inplayPopular={inplayPopular} />
            </IonHeader>
            <IonContent className="default-page__content">
              <Promotion />
              { <BonusBanner />}
              {isSignedUpWithNumberInBranchHelper() ? (
                  <WhatsappBanner
                    link={JSON.parse(localStorage.getItem('user_data'))?.wa}
                  />
                ): <VerifButtons auth={auth} actions={actions} />
              }
              <Verification />
              {/*{<BonusCard/>}*/}
              {/*{<Verification />}*/}
              
              {navigationID == 99999&&
                <CasinoProvider />
              }
              

              {/* {(navigationID != 99999 && menu?.length && inplayData?.length)? */}
              {(navigationID != 99999 && inplayData?.length)&&
                <div ref={inplayRef}>
                  <InPlayPopular
                    pathname={pathname}
                    displayedBack={displayedBack}
                    betPlacing={betPlacing}
                    timeSettings={timeSettings}
                    setDisplayedBack={setDisplayedBack}
                    balanceSettings={balanceSettings && balanceSettings.settings}
                    // inPlayEvents={inplayPopular && inplayPopular.inPlayEvents}
                    inPlayEvents={inplayData}
                    type={headerType['INPLAY']}
                    orderCount={(orderCount && orderCount) || 0}
                    promotions={promotions}
                    navigationID={navigationID}
                  />
                </div>
              }

              

              
              {/* {inplayPopular && inplayPopular.inPlayEvents ? (
                <>
                  <InPlayPopular
                    pathname={pathname}
                    displayedBack={displayedBack}
                    betPlacing={betPlacing}
                    timeSettings={timeSettings}
                    setDisplayedBack={setDisplayedBack}
                    balanceSettings={
                      balanceSettings && balanceSettings.settings
                    }
                    inPlayEvents={inplayPopular && inplayPopular.inPlayEvents}
                    type={headerType['INPLAY']}
                    orderCount={(orderCount && orderCount.count) || 0}
                    promotions={promotions}
                  />
                  <InPlayPopular
                    displayedBack={displayedBack}
                    betPlacing={betPlacing}
                    timeSettings={timeSettings}
                    setDisplayedBack={setDisplayedBack}
                    balanceSettings={
                      balanceSettings && balanceSettings.settings
                    }
                    inPlayEvents={inplayPopular && inplayPopular.popularEvents}
                    type={headerType['POPULAR']}
                  />
                </>
              ) : (
                <LoaderContent />
              )} */}

              {(navigationID != 99999 && ExchangeGamesData.length)&&
                <IonItemGroup className="inplay-content">
                  <ExchangeGamesGridList
                    type={headerType['POPULAR']}
                    itemHeaderData={leftSideMenuMapper['Exchange Games']}
                    itemsData={ExchangeGamesData}
                  />
                  <PopularGamesGridList
                    type={headerType['POPULAR']}
                    itemHeaderData={leftSideMenuMapper['Popular Games']}
                    itemsData={PopularCasinoGamesData}
                  />
                  <CasinoGridList
                    type={headerType['POPULAR']}
                    itemHeaderData={leftSideMenuMapper['Casino Provider']}
                    itemsData={CasinoProviderDATA}
                  />
                </IonItemGroup>
              }

              {(navigationID != 99999 && upcomingData?.length)&&
                <InPlayPopular
                  displayedBack={displayedBack}
                  betPlacing={betPlacing}
                  timeSettings={timeSettings}
                  setDisplayedBack={setDisplayedBack}
                  balanceSettings={balanceSettings && balanceSettings.settings}
                  // inPlayEvents={inplayPopular && inplayPopular.popularEvents}
                  inPlayEvents={upcomingData}
                  type={headerType['POPULAR']}
                  navigationID={navigationID}
                />
              }

              {(fortuneWheel?.url && auth) && <iframe className="default-page__content-iframe" src={fortuneWheel?.url}></iframe>}

              <LegalAgePopover open={legalAgePopoverShow} />
              <KycPopover open={kycPopoverShow} />
              <MoreRulesPopover open={moreRulesPopoverShow} />
              {renderFooter && <FooterDesktop />}
              {/* <div style={{position: 'absolute', left: '10px', bottom: '20px', zIndex: '11111'}}>
              <img src={whatsAppImg} alt="" />
             </div> */}
            </IonContent>
          </IonPage>
        </>
      ) : (
        <>
          {/* {bannerOpen && <BannerPopUp />} */}
          <div style={{ height: '100%' }}>
            <div className="default-page__content">
              <Promotion />
              {auth && <BonusBanner />}
              {isSignedUpWithNumberInBranchHelper() && (
                  <WhatsappBanner
                    link={JSON.parse(localStorage.getItem('user_data'))?.wa}
                  />
                )}
                

              {navigationID == 99999&&
                <CasinoProvider />
              }
              

              {/* {(navigationID != 99999 && menu?.length && inplayData?.length)&& */}
              {(navigationID != 99999 && inplayData?.length)&&
                <InPlayPopular
                  pathname={pathname}
                  displayedBack={displayedBack}
                  betPlacing={betPlacing}
                  timeSettings={timeSettings}
                  setDisplayedBack={setDisplayedBack}
                  balanceSettings={balanceSettings && balanceSettings.settings}
                  // inPlayEvents={inplayPopular && inplayPopular.inPlayEvents}
                  inPlayEvents={inplayData}
                  type={headerType['INPLAY']}
                  orderCount={(orderCount && orderCount) || 0}
                  promotions={promotions}
                  navigationID={navigationID}
                /> 
              }

              {/* {inplayPopular && inplayPopular.inPlayEvents ? (
                <>
                  <InPlayPopular
                    pathname={pathname}
                    displayedBack={displayedBack}
                    betPlacing={betPlacing}
                    timeSettings={timeSettings}
                    setDisplayedBack={setDisplayedBack}
                    balanceSettings={
                      balanceSettings && balanceSettings.settings
                    }
                    inPlayEvents={inplayPopular && inplayPopular.inPlayEvents}
                    type={headerType['INPLAY']}
                    orderCount={(orderCount && orderCount.count) || 0}
                    promotions={promotions}
                  />
                  <InPlayPopular
                    displayedBack={displayedBack}
                    betPlacing={betPlacing}
                    timeSettings={timeSettings}
                    setDisplayedBack={setDisplayedBack}
                    balanceSettings={
                      balanceSettings && balanceSettings.settings
                    }
                    inPlayEvents={inplayPopular && inplayPopular.popularEvents}
                    type={headerType['POPULAR']}
                  />
                </>
              ) : (
                <LoaderContent />
              )} */}

              {(navigationID != 99999 && ExchangeGamesData.length)&&
                <IonItemGroup className="inplay-content">
                  <ExchangeGamesGridList
                    type={headerType['POPULAR']}
                    itemHeaderData={leftSideMenuMapper['Exchange Games']}
                    itemsData={ExchangeGamesData}
                  />
                  <PopularGamesGridList
                    type={headerType['POPULAR']}
                    itemHeaderData={leftSideMenuMapper['Popular Games']}
                    itemsData={PopularCasinoGamesData}
                  />
                  <CasinoGridList
                    type={headerType['POPULAR']}
                    itemHeaderData={leftSideMenuMapper['Casino Provider']}
                    itemsData={CasinoProviderDATA}
                  />
                </IonItemGroup>
              }

              <LegalAgePopover open={legalAgePopoverShow} />
              <KycPopover open={kycPopoverShow} />
              <MoreRulesPopover open={moreRulesPopoverShow} />
              {(fortuneWheel?.url && auth) && <iframe className="default-page__content-iframe" src={fortuneWheel?.url}></iframe>}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default connectDefaultPage()(DefaultPage)
