import React, {useEffect, useState} from "react";
import DatePicker from "react-date-picker";
import { IonCheckbox, IonContent, IonHeader, IonItem, IonPage } from '@ionic/react'
import {connectMyOpenBets} from "./MyOpenBets.connect";
import OpenBets from "../../components/OpenBets/OpenBets";
import OpenBetsTable from "../../components/OpenBetsTable/OpenBetsTable";
import PastBetsTable from "../../components/PastBetsTable/PastBetsTable";
import Toolbar from "../../components/Toolbar/Toolbar";
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
import "./MyOpenBets.scss";
import { MyBetsData } from "../../constants/MyBetsData";
import { BettingEventsData } from "../../constants/BettingEventsData";
import moment from "moment";
import { Socket } from "../../services/socket";
import { useHistory } from "react-router";


const MyOpenBets = ({openBets, user, timeSettings, actions, elementsParams}) => {
    const [activeButton, setActiveButton] = useState("matched");
    const [isCurrent, setIsCurrent] = useState(true);
    const [selectedDateFrom, setSelectedDateFrom] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
    const [selectedDateTo, setSelectedDateTo] = useState(new Date());
    const [pastBets, setPastBets] = useState(null);
    const [openBetsTime, setPastBetsTime] = useState(null);
    const [validationError, setValidationError] = useState("");
    const [selectedFilter, setSelectedFilter] = useState('4');
    const history = useHistory();
    const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

    useEffect(() => {
      if(!user.verifytoken) {
        history.push('default-page');
      }
    }, [user]);


    const dateValidation = (from, to) => {
        if (from > to) return setValidationError("'To' date must be greater than 'From' date.");
        else if (setValidationError) setValidationError("");
        if (new Date().setHours(23,59,59,999) < to) return setValidationError("'To' date must not be greater than today's date.");
        else if (setValidationError) setValidationError("");
        if (!validationError) {
            if (isCurrent) {
                actions.requestOpenBetsWithDate(from, to)
                  .then(res => setPastBetsTime(res?.data?.result))
                  .catch((e) => console.log("requestOpenBetsWithDate error = ", e));
            } else {
                actions.requestPastBets(from, to)
                  .then(res => setPastBets(res?.data?.result))
                  .catch((e) => console.log("requestPastBets error = ", e));
            }
        }
    };

    let todayDate = new Date()

    const getMonth = (date) => {
      return `${date.toLocaleDateString('en-GB', {
        month: '2-digit',
      })}`
    }

    const getDate = (date) => {
      return `${date.toLocaleDateString('en-GB', {
        day: '2-digit',
      })}`
    }

    const getWithTimeLimit = () => {
        let DateFrom = selectedDateFrom && new Date(selectedDateFrom).setHours(0,0,0,0);
        let DateTo = selectedDateTo && new Date(selectedDateTo).setHours(23,59,59,999);
        dateValidation(DateFrom, DateTo);
        // if (!validationError) {
        //     if (isCurrent) {
        //         actions.requestOpenBetsWithDate(DateFrom, DateTo)
        //           .then(res => setPastBetsTime(res?.data?.result))
        //           .catch((e) => console.log("requestOpenBetsWithDate error = ", e));
        //     } else {
        //         actions.requestPastBets(DateFrom, DateTo)
        //           .then(res => setPastBets(res?.data?.result))
        //           .catch((e) => console.log("requestPastBets error = ", e));
        //     }
        // }
    };
    // useEffect( () => {
    //     getWithTimeLimit();
    // }, [isCurrent]);


    useEffect(() => {
      Socket.on('get-bets-success', GetBetsSuccess);
  
      return () => {
        Socket.off('get-bets-success', GetBetsSuccess);
      };
    }, []);


    const GetBetsSuccess = (...args) => {
      actions.getOpenBetsSuccess(args[0]);
    }

    const requestBets = () => {
      let sendData = {
        token: user?.verifytoken,
        filter: {
          username: user?.details?.username,
          eventTypeId: selectedFilter,
          deleted: activeButton==='matched'? false : true,
          result: {"$ne":"ACTIVE"},
          createDate: {
            $gte: moment(selectedDateFrom).format('YYYY-MM-DD'),
            $lte: moment(selectedDateTo).format('YYYY-MM-DD'),
          },
        },
        sort: { 
          betentertime:-1 
        },
      };
  
      Socket.emit('get-bets', sendData);
    }
  
    useEffect(() => {
      if(activeButton && selectedFilter && selectedDateFrom && selectedDateTo) {
        requestBets();
      }
    }, []);

    const submit = () => {
      if(activeButton && selectedFilter && selectedDateFrom && selectedDateTo) {
        requestBets();
      }
    }


    const handleFilter = (e) => {
      const filterVal = e.target.value;
      setSelectedFilter(filterVal);
  
      // if(openBets && accountStatement?.length >0) {
      //   filterStatements(filterVal);
      // }
    }



    return (
    <>
      {width < 1024 ? (
        <IonPage>
          <IonHeader>
            <Toolbar />
          </IonHeader>
          <IonContent className="MyOpenBets" fullscreen>
            <>
              <div className="page-title-wrapper">
                {/* <h2 className="page-title">Open Bets</h2> */}
                <h2 className="page-title">My Bets</h2>
              </div>

              {/* <OpenBets openBets={openBets} redirect={true} /> */}

              <div className="MyOpenBets__page-menu-buttons-wrapper"
                style={{padding: '20px 20px 0 20px'}}
              >
                <div className="column">
                  <div className="alt">
                    <input 
                      id="matched" 
                      type="checkbox" 
                      formcontrolname="is_matched" 
                      name="is_matched" 
                      checked={activeButton === "matched"}
                      onChange={(e)=>{setActiveButton("matched")}}
                      // className="custom-control-input ng-untouched ng-pristine ng-valid" 
                    />
                    <label htmlFor="matched" className="custom-control-label">
                      <span >Matched</span>
                    </label>
                  </div>
                  <div className="alt">
                    <input 
                      id="unmatched" 
                      type="checkbox" 
                      formcontrolname="is_matched" 
                      name="is_matched" 
                      checked={activeButton === "deleted"}
                      onChange={(e)=>{setActiveButton("deleted")}}
                      // className="custom-control-input ng-untouched ng-pristine ng-valid" 
                    />
                    <label htmlFor="unmatched" className="custom-control-label">
                      <span>Deleted</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="account-statement-page__filter-content">
                <div className="custom-date-picker">
                  <DatePicker
                    onChange={setSelectedDateFrom}
                    value={selectedDateFrom}
                    showLeadingZeros={true}
                    locale={'en-US'}
                    format={'dd/MM/yyyy'}
                    calendarIcon={<span role="img" aria-label="calendar">📅</span>}
                    clearIcon={null}
                    className="react-date-picker"
                    dayPlaceholder={getDate(todayDate)}
                    monthPlaceholder={getMonth(todayDate)}
                    yearPlaceholder={todayDate.getFullYear()}
                  />
                </div>

                <div className="custom-date-picker">
                  <DatePicker
                    onChange={setSelectedDateTo}
                    value={selectedDateTo}
                    showLeadingZeros={true}
                    locale={'en-US'}
                    format={'dd/MM/yyyy'}
                    calendarIcon={<span role="img" aria-label="calendar">📅</span>}
                    clearIcon={null}
                    className="react-date-picker"
                    dayPlaceholder={getDate(todayDate)}
                    monthPlaceholder={getMonth(todayDate)}
                    yearPlaceholder={todayDate.getFullYear()}
                  />
                </div>

                <select 
                  formcontrolname="event_type_id" 
                  name="reportType" 
                  className="filter-select"
                  style={{padding: '7px'}}
                  value={selectedFilter}
                  onChange={handleFilter}
                >
                  {(BettingEventsData.length>0)&&
                    BettingEventsData.map((item, index) => {
                      return(
                        <option 
                          key={item.eventTypeId + index} 
                          value={item.eventTypeId}
                        >{item.name}
                        </option>
                      );
                    })
                  }
                </select>

                <button className="account-statement-page__date-search-button"
                  onClick={() => {
                    submit();
                    // getWithTimeLimit()
                    // setfilterOpen((prevValue) => !prevValue)
                    // setFromToDays(daysFromTo(selectedDateFrom, selectedDateTo))
                  }}
                >
                  <span>Search</span>
                </button>
              </div>

              <OpenBetsTable
                // bets={ (openBetsTime && openBets[activeButton]) || (openBets !== null && openBets[activeButton])}
                bets={openBets}
                timeSettings={timeSettings}
                title={`${activeButton} Bets`}
                redirect={true}
                activeButton={activeButton}
              />
            </>
                  {/*<FooterDesktop />*/}
          </IonContent>
        </IonPage>
      ) : (
        <div className="MyOpenBets" fullscreen>
          <div className="MyOpenBets__page">
            <div className="page-title-wrapper  MyOpenBets__page-title-wrapper">
              <h2 className="page-title MyOpenBets__page-title">My Bets</h2>
            </div>
            <div className="MyOpenBets__page-menu">
              <div className="MyOpenBets__page-menu-buttons-wrapper">
                {/* <div className="MyOpenBets__page-menu-buttons-row MyOpenBets__page-menu-buttons-row-time">
                  <div onClick={() => setIsCurrent(true)}
                       className={`MyOpenBets__page-menu-button ${isCurrent ? "active" : ""}`}>
                    <span>Current</span>
                  </div>
                  <div onClick={() => setIsCurrent(false)}
                       className={`MyOpenBets__page-menu-button ${!isCurrent ? "active" : ""}`}>
                    <span>Past</span>
                  </div>
                </div> */}
                  {/* { isCurrent &&
                    <div className="MyOpenBets__page-menu-buttons-row">
                        <div onClick={() => setActiveButton("matched")}
                             className={`MyOpenBets__page-menu-button ${activeButton === "matched" ? "active" : ""}`}>
                            <span>Matched</span>
                        </div>
                        <div onClick={() => setActiveButton("unmatched")}
                             className={`MyOpenBets__page-menu-button ${activeButton === "unmatched" ? "active" : ""}`}>
                            <span>Unmatched</span>
                        </div>
                    </div>
                  } */}

                <div className="column">
                  <div className="alt">
                    <input 
                      id="matched" 
                      type="checkbox" 
                      formcontrolname="is_matched" 
                      name="is_matched" 
                      checked={activeButton === "matched"}
                      onChange={(e)=>{setActiveButton("matched")}}
                      // className="custom-control-input ng-untouched ng-pristine ng-valid" 
                    />
                    <label htmlFor="matched" className="custom-control-label">
                      <span >Matched</span>
                    </label>
                  </div>
                  <div className="alt">
                    <input 
                      id="unmatched" 
                      type="checkbox" 
                      formcontrolname="is_matched" 
                      name="is_matched" 
                      checked={activeButton === "deleted"}
                      onChange={(e)=>{setActiveButton("deleted")}}
                      // className="custom-control-input ng-untouched ng-pristine ng-valid" 
                    />
                    <label htmlFor="unmatched" className="custom-control-label">
                      <span>Deleted</span>
                    </label>
                  </div>
                </div>
              </div>
              
              <div className="MyOpenBets__page-menu-filter-content" style={{marginTop: '10px'}}>
                <div className="MyOpenBets__page-menu-filter-content-date-input">
                  <p>From</p>
                  <div>
                    <DatePicker
                      onChange={setSelectedDateFrom}
                      value={selectedDateFrom}
                      locale={'en-US'}
                      showLeadingZeros={true}
                      clearIcon={null}
                      calendarIcon={null}
                      format={'dd/MM/yyyy'}
                      dayPlaceholder={getDate(todayDate)}
                      monthPlaceholder={getMonth(todayDate)}
                      yearPlaceholder={todayDate.getFullYear()}
                      />
                  </div>
                </div>
                <div className="MyOpenBets__page-menu-filter-content-date-input">
                  <p>To</p>
                    <div>
                      <DatePicker
                        onChange={setSelectedDateTo}
                        value={selectedDateTo}
                        locale={'en-US'}
                        showLeadingZeros={true}
                        clearIcon={null}
                        calendarIcon={null}
                        format={'dd/MM/yyyy'}
                        dayPlaceholder={getDate(todayDate)}
                        monthPlaceholder={getMonth(todayDate)}
                        yearPlaceholder={todayDate.getFullYear()}
                      />
                  </div>
                </div>

                <div className="account-statement-page__filters-date-input" style={{ marginRight: "15px" }}>
                  <p>Type</p>
                  <div>
                    <select 
                      formcontrolname="event_type_id" 
                      name="reportType" 
                      className="react-date-picker__wrapper filter-select"
                      style={{padding: '7px'}}
                      value={selectedFilter}
                      onChange={handleFilter}
                    >
                      {(BettingEventsData.length>0)&&
                        BettingEventsData.map((item) => {
                          return(
                            <option 
                              key={item.eventTypeId} 
                              value={item.eventTypeId}
                            >{item.name}
                            </option>
                          );
                        })
                      }
                    </select>
                  </div>
                </div>

                <button className="MyOpenBets__page-menu-date-search-button"
                        // disabled={!(selectedDateFrom && selectedDateTo)}
                        onClick={() =>{
                          submit();
                          // getWithTimeLimit
                        }}
                >
                <span>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.8023 11.8409L9.59762 8.623C10.4216 7.67732 10.8731 6.48745 10.8731 5.24874C10.8731 2.35463 8.4342 0 5.43654 0C2.43888 0 0 2.35463 0 5.24874C0 8.14286 2.43888 10.4975 5.43654 10.4975C6.5619 10.4975 7.63432 10.1698 8.5512 9.5477L11.7803 12.7901C11.9152 12.9254 12.0968 13 12.2913 13C12.4754 13 12.6501 12.9322 12.7827 12.809C13.0645 12.5472 13.0735 12.1132 12.8023 11.8409ZM5.43654 1.36924C7.65229 1.36924 9.45485 3.10954 9.45485 5.24874C9.45485 7.38795 7.65229 9.12825 5.43654 9.12825C3.2208 9.12825 1.41823 7.38795 1.41823 5.24874C1.41823 3.10954 3.2208 1.36924 5.43654 1.36924Z"
                          fill="white" />
                    </svg>
                </span>
                  <span>Search</span>
                </button>
                {validationError && (<div className="MyOpenBets__page-menu-filter-content-error">{validationError}</div>)}
              </div>
            </div>
              { isCurrent &&
                <OpenBetsTable
                  // bets={ (openBetsTime && openBets[activeButton]) || (openBets !== null && openBets[activeButton])}
                  bets={openBets}
                  timeSettings={timeSettings}
                  title={`${activeButton} Bets`}
                  redirect={true}
                  activeButton={activeButton}
                />
              }
              { !isCurrent &&
                <PastBetsTable
                  bets={pastBets}
                  timeSettings={timeSettings}
                  title={"Past"}
                />
              }
          </div>
        </div>
      )}
    </>
    );
};

export default connectMyOpenBets()(MyOpenBets);
