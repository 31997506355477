import { createSlice } from '@reduxjs/toolkit'

export const casinoProviderSlice = createSlice({
    name: 'casinoProvider',
    initialState: {
        casinoType: '',
        selectedProvider: '',
    },
    reducers: {
        setCasinoType: (state, action) => ({ ...state, casinoType: action.payload }),
        setSelectedProvider: (state, action) => ({ ...state, selectedProvider: action.payload}),
    },
})

export const {
    setCasinoType,
    setSelectedProvider
} = casinoProviderSlice.actions

export default casinoProviderSlice.reducer
