import './oddbutton.scss'
import React, { useEffect, useState } from 'react';
import isNumber from '../../helpers/isNumber.helper'
import { getFormattedNumberWithComma } from "../../helpers/formatted-number.helper";
import { connectOddbutton } from './oddbutton.connect'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper';

const OddButton = connectOddbutton()(({
  market,
  auth,
  oddData,
  balanceSettings,
  isBack,
  betPlacing,
  oneClick,
  actions,
  showBlock = true,
  showEmpty = false,
  centered = false,
  mainBlock = true,
  isFancy = false,
  marketNum,
  setClicked,
  clicked
}) => {
    const [odd, setOdd] = useState(null)
    const [isChanged, setIsChanged] = useState(false)
    const [color, setColor] = useState("")

    const getBtnActive = () => {
        const isNotActive = isBtnNotActive() ? 'not-active' : ''
        const active = !isNotActive && betPlacing.market === (market && market.id) && betPlacing.runner === (oddData && oddData.runner && oddData.runner.id) && betPlacing.isBack === isBack && marketNum === clicked ? 'active' : ''
        return  `${isNotActive} ${active} `
    }


    useEffect(() => {
        const isNotActive = isBtnNotActive() ? 'not-active' : ''
        const active = !isNotActive && betPlacing.market === (market && market.id) && betPlacing.runner === (oddData && oddData.runner && oddData.runner.id) && betPlacing.isBack === isBack && marketNum === clicked ? 'active' : ''

        if (active && betPlacing.priceActual !== (oddData && oddData.price) && (betPlacing.mtype == 'MATCH_ODDS_SB' || betPlacing.mtype == 'BOOKMAKER' || betPlacing.mtype == 'INNINGS_RUNS')) {
            actions.setPriceActual(oddData && oddData.price)
        }
    }, [betPlacing?.priceActual, oddData?.price])

    const isBtnNotActive = () => {
        // console.log("notAcrive func")


        
        if(isFancy) {
            // console.log("Status: ", market?.marketBook?.status);


            return market?.marketBook?.status !== 'OPEN' ||
                    market?.marketBook?.status === 'SUSPENDED';
                    // market?.marketBook?.statusLabel !== "OPEN" ||
                    // market?.marketBook?.statusLabel === 'SUSPENDED';
        }else {
            return market?.marketBook?.status !== 'OPEN' || 
                    !oddData?.runner?.status ||
                    !oddData?.runner?.status === 'SUSPENDED';
        }

        // return !market?.isBettable || !isNumber(odd?.price);
    }

    const getOddPrice = (oddData = odd) => {
         const price = oddData && oddData.price;
        return price
    }

    const getPriseFor_SB = (marketId) => {
        return (marketId && marketId.slice(marketId.length - 3) === '_SB' ? ((odd?.price - 1) * 100).toFixed(2).replace('.00', '') : odd?.price);
    }

    const getOddVolume = () => {
        const size = odd && odd.size;
        const volumeMultiplier = (market && balanceSettings && balanceSettings.settings && balanceSettings.settings[market.eventTypeId] && balanceSettings.settings[market.eventTypeId].volumeMultiplier) || 1;
        // if (!size || !volumeMultiplier || market.mtype === 'MATCH_ODDS_SB') {
        //     return;
        // }
        if (!size ) {
            return;
        }
        if(market?.id?.includes('_BR')) {
            // const resultVolume = Math.ceil(size * volumeMultiplier);
            // return <div className="odd-button__volume">{resultVolume >= 500000 ? '500.000+' : getFormattedNumberWithComma(resultVolume)}</div>
            return null
        }
        if (market?.mtype && market?.mtype === 'MATCH_ODDS_SB') {
            return;
        }
        const resultVolume = Math.ceil(size * volumeMultiplier);
        return <div className="odd-button__volume">{resultVolume >= 500000 ? '500.000+' : getCurrencyFormat(resultVolume)}</div>
    }

    const select = () => {
        const marketId = (market && market?.marketId)? market?.marketId : market?.marketBook?.marketId;
        const runnerId = (oddData && oddData.runner) && oddData.runner.id;

        // console.log("Market: ", market);
        // console.log("Market Id: ", marketId);
        // console.log("Runner Id: ", runnerId);

        // const runnerName = oddData && oddData.runner && oddData.runner.name;
        // console.log("Runner Name: ", runnerName);
        // console.log("Event Name: ", market && market?.eventName);
        // console.log("Is Back: ", isBack);
        // console.log("Price: ", oddData && oddData.price);
        // console.log("Price: ", oddData && oddData.price);
        // console.log("Bet Delay: ", ((market && market?.marketBook && market?.marketBook?.betDelay) || 0 ))
        // console.log("Event Type Id: ", market && market?.eventTypeId)
        // console.log("Market Type: ", market && market?.marketType);
        
        if (!marketId || !oddData.runner) return
        actions.setMarket(marketId)
        actions.setMarketType(market && market?.marketType)
        actions.setMarketName(market && market?.marketName)
        actions.setRunner(runnerId)
        actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
        // actions.setEventName(market && market?.event && market.event.name)
        actions.setEventName(market && market?.eventName)
        actions.setEventId(market && market?.eventId)
        actions.setIsBack(isBack)
        actions.setPrice(oddData && oddData.price)
        actions.setPriceActual(oddData && oddData.price);
        actions.setIsCashOut(false);

        if(market?.marketType == 'SESSION') {
            actions.setFancyPrice(oddData && oddData.size);
        }

        // actions.setDelay(market && market.betDelay)
        actions.setDelay((market && market?.marketBook && market?.marketBook?.betDelay) || 0)
        actions.setMtype(market && market.mtype)
        actions.setBtype(market && market.btype)
        actions.setLine(oddData && oddData.line)
        // actions.setStartTime(market && market?.start)
        actions.setStartTime(market && market?.openDate)
        actions.setEventTypeId(market && market?.eventTypeId)
        if (oneClick.active) {
            actions.setStake(oneClick.settings[oneClick.selected - 1])
            actions.setBetPlacingPopoverStage(false)
            actions.setShowBetPlacingPopover(true)
            actions.setFromOneClick(true)
        } else {
            actions.setFromOneClick(false)
            actions.setStake(null)
        }
        if(auth) {
            // actions.requestBetPlacingStart(marketId, runnerId)
            // .then((response) => {
            // })
            // .catch((err) => console.log('err = ', err))
        }
    }

    useEffect(() => {
        if (isDataChanged()) {
            setIsChanged(true);
            setTimeout(() => {
                setIsChanged(false);
            }, 300)
            setOdd(oddData)
        }
        
    }, [oddData?.price, oddData?.size])

    useEffect(() => {
         setColor(isBack ? `back-color` : `lay-color`)
    }, [isBack])

    const isDataChanged = () => {
        return getOddPrice() !== getOddPrice(oddData) || odd?.size !== oddData?.size
    }



    return (
        <span className={`odd-button ${color} ${getBtnActive()} ${isChanged ? 'change-color' : ''} ${mainBlock ? "" : "secondary"}`}
            onClick={() => {
                
                // if (!isBtnNotActive() && (oddData && oddData.price > 1) && (isFancy ? oddData.line : true)) {
                if (!isBtnNotActive() && (oddData && oddData.price > 1)) {

                    setClicked && setClicked(marketNum)
                    select()
                }
            }}
        >
            <span className={`odd-button__inner ${centered ? 'odd-button__inner--centered' : ''} ${isFancy && !oddData.line ? "": ""}`}>
                {(isFancy)?
                    <>
                        {(isNumber(Number(odd?.price)) && !!oddData)&& <div className={`odd-button__price`}> {oddData?.price}</div>}
                    </>
                    :
                    <div className={`${isFancy ? "odd-button__volume": "odd-button__price"}`}>
                        {(isNumber(Number(odd?.price)))&& odd?.price === 0 ? '-' : isNumber(Number(odd?.price)) ? getCurrencyFormat(getPriseFor_SB(market && market.id)) : (showEmpty ? '' : '-')}
                    </div>
                }
                
                {isNumber(Number(odd?.price)) && odd?.price === 0 ? '-' : isNumber(Number(odd?.price)) ? getOddVolume() : ''}
            </span>
        </span>
    )
})

export default OddButton
