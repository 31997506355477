import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestChangePassword } from '../../store/user/user.thunks'
import { setIsNavigationOpen, setIsNavigationID, setIsNavigationName, setIsAnimatedMenu, setIsMoreDetailed, setIsDetailedGroup, setIsDetailedGroupName, setIsOpenSport, setIsEventDetailed, setRenderIonContent, setSportsName} from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice';
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'
import { requestSportPageEventDetailed } from '../../store/sportPageEvent/sportPageEvent.thunks';
import { requestSportData } from '../../store/sportEvent/sportEvent.thunks';
import { setSlotCasinoPopUp, openSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice';
import { requestMenu } from '../../store/menu/menu.thunks';
import { getMenuSuccess } from '../../store/menu/menu.slice'
import { requestHomeAllCompetition, requestHomeAllEvents } from '../../store/homeMarketEvents/homeMarketEvents.thunks';
import { getHomeAllCompetitionSuccess } from '../../store/homeMarketEvents/homeMarketEvents.slice';
import { setCasinoType } from '../../store/casinoprovider/casinoprovider.slice';
import { setLoginNavigationData } from '../../store/loginNavigation/LoginNavigation.slice';

export const connectLeftSideMenuDesktop = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      menu: state.menu,
      timeSettings: state.timeSettings,
      auth: state.auth,
      sportPageEvent: state.sportPageEvent,
      sportItemData: state.sportItemData,
      moreDetailed: state.navigationHeaderMenu.moreDetailed,
      eventDetailed: state.navigationHeaderMenu.eventDetailed,
      sportsName: state.navigationHeaderMenu.sportsName,
      slotCasinoPopUp: state.slotCasinoPopUp,
      homeMarketEvents: state.homeMarketEvents,

    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSportPageEventDetailed,
      requestSportData,

      setIsNavigationOpen,
      setIsNavigationID,
      setIsNavigationName,
      setIsAnimatedMenu,
      setIsMoreDetailed,
      setIsDetailedGroup,
      setIsDetailedGroupName,
      setLoginNew,
      setIsOpenSport,
      setIsEventDetailed,
      setRenderIonContent,
      setSportsName,
      setSlotCasinoPopUp,
      openSlotCasinoPopUp,
      requestMenu,
      getMenuSuccess,
      requestHomeAllCompetition,
      getHomeAllCompetitionSuccess,
      requestHomeAllEvents,
      setCasinoType,
      setLoginNavigationData
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
