import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './ExchangeGamesGridList.scss'
import { IonList, IonListHeader } from "@ionic/react";
import { useHistory } from 'react-router';
import EventTypeBanners from '../EventTypeBanners/EventTypeBanners';
import { headerType } from '../../helpers/InPlayPopular.helper';
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'
import { openSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice';
import { setLoginNavigationData } from '../../store/loginNavigation/LoginNavigation.slice';


const ExchangeGamesGridList = ({ itemHeaderData, itemsData, type }) => {
    const history = useHistory();
    const auth = useSelector((state) => state.auth)
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const slider = useRef(null);
    const [isDown, setIsDown] = useState(false);
    const [isSlide, setIsSlide] = useState(false);
    const [startX, setStartX] = useState("");
    const [scrollLeft, setScrollLeft] = useState("");

    const getBanner = () => {
        if (headerType['INPLAY'] == type) {
             return <EventTypeBanners eventType={itemHeaderData?.id} />
        } else return null
    }

    const renderHeader = () => {
        return <IonListHeader 
            className="inplay-item-list-exchange__header "
            style={{backgroundColor: '#ff4500', color: '#fff'}}
        >
            <div className="inplay-item-list-exchange__header-content">
                <div className="inplay-item-list-exchange__header-logo">
                    <i className={`inplay-content__logo-icon inplay-content__logo-icon--exchange-games inplay-color-icon`} />
                    {itemHeaderData?.name}
                    {/* <span>Indian Card Games</span> */}
                </div>
            </div>
        </IonListHeader>
    }

    return (
        <IonList className="inplay-item-list_exchangeGames">
            {getBanner()}
            {itemHeaderData ? renderHeader() : null}
            <div className="exchangeGames-content"
                 ref={slider}
                 onMouseDown={(e) => {
                     setIsDown(true)
                     setStartX(e.pageX - slider.current.offsetLeft)
                     setScrollLeft(slider.current.scrollLeft)
                 }}
                 onMouseUp={() => {
                     if (isSlide) {
                         setTimeout(() =>    setIsSlide(false), 500)
                     }
                     setIsDown(false)
                 }}
                 onMouseMove={(e) => {
                     if(!isDown) return;
                     e.preventDefault();
                     setIsSlide(true)
                     const x = e.pageX - slider.current.offsetLeft;
                     const walk = (x - startX) * 3; //scroll-fast
                     slider.current.scrollLeft = scrollLeft - walk;
                 }}
                 onMouseLeave={() => {
                     setIsDown(false)
                     setIsSlide(false)
                 }}
            >
                {itemsData && itemsData.map((item, index) => {
                    return (
                        // (item?.image)&& 
                        (item?.eventTypeId)&& 
                        <div className="exchangeGames-item" key={index}
                            style={{backgroundColor: `${item?.backgroundColour}`}}
                            onClick={isSlide ? () => {} : ()=> {
                                if(user?.verifytoken) {
                                    history.push(`/casino_live/${item?.gameId}/${item?.tableId}`);
                                    dispatch(openSlotCasinoPopUp())
                                }else {
                                    dispatch(setLoginNew(true));
                                    dispatch(setLoginNavigationData({ page: 'casino_live', ids: [item?.gameId, item?.tableId] }));
                                }
                            }
                        }>
                            <div className="exchangeGames-img-body" style={{ backgroundImage: `url(${item?.image || itemHeaderData?.banner})`}}>

                            </div>
                        </div>
                    )
                })}

            </div>
        </IonList>

    )
}

export default ExchangeGamesGridList
