import React, { useEffect, useState } from 'react'
import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage } from '@ionic/react'
import './MyMarkets.scss'
import { connectMyMarkets } from './MyMarkets.connect'
import Toolbar from '../../components/Toolbar/Toolbar'
import LoaderContent from '../../components/LoaderContent/LoaderContent'
import leftSideMenuMapper from '../../helpers/getLeftSideMenuMapper.helper'
import { useHistory } from "react-router-dom";
import moment from 'moment'

const MyMarkets = ({ auth, openBets, allEventsOpenBets }) => {
  const history = useHistory();
  const [currOpenBets, setCurrOpenBets] = useState([]);

  useEffect(() => {
    if(!auth) {
      history.push("/default-page");
    }
  }, [auth])

  useEffect(() => {
    if(allEventsOpenBets && allEventsOpenBets?.length) {
      const uniqueEvents = allEventsOpenBets.reduce((acc, current) => {
        const x = acc.find(item => item.eventId === current.eventId);
        if (!x) {
          acc.push(current);
        }
        return acc;
      }, []);
      setCurrOpenBets(uniqueEvents);
    }else {
      setCurrOpenBets([]);
    }
  }, [allEventsOpenBets]);


  const prepareMarkets = () => {
    // if (openBets.matched && openBets.unmatched) {
    if (currOpenBets && currOpenBets?.length) {
      // const markets = [...Object.values(openBets.matched), ...Object.values(openBets.unmatched)];
      const markets = [...Object.values(currOpenBets)];
      markets.sort((function (a, b) {
        if (moment(a.betPlacedDate).isBefore(moment(b.betPlacedDate))) {
          return 1
        }
        if (moment(a.betPlacedDate).isAfter(moment(b.betPlacedDate))) {
          return -1
        }
        return 0
      }))

      if (markets.length) {
        return markets.map(market => {
          const menuHelperItem = Object.entries(leftSideMenuMapper).map(([key, value]) => value)
            .find(menuItemWrapper => market.eventTypeId == menuItemWrapper.id);
          return (
            <IonItem 
              className="MyMarket__list__item" 
              key={market._id} 
              onClick={() => {
                if(market.marketType === 'ballbyball' || market.marketType == 'virtual') {
                  history.push(`/virtual-event-page/${market.eventTypeId}`)
                }else {
                  history.push(`/event-page/${market.eventTypeId}/${market.eventId}`)
                }
              }}
            >
              <span className={`MyMarket__list__item__icon eventIcon ${menuHelperItem?.name.replace(/\s/g, '')}`}>
                { (!menuHelperItem?.isSvg && menuHelperItem?.img) || ''}
              </span>
            <IonLabel className="MyMarket__list__item__label">{(market.marketType === 'ballbyball' || market.marketType == 'virtual')? `🎮 ${market.eventName}` :  market.eventName}</IonLabel>
          </IonItem>);
        })
      }
      else {
        return <IonLabel className="MyMarket__list__empty">You don't have any markets</IonLabel>
      }
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent className="MyMarket" fullscreen>
        {/* {openBets */}
        {allEventsOpenBets
          ?
          <IonList className="MyMarket__list">
            <IonListHeader className="MyMarket__list__header">
              My Markets
            </IonListHeader>
            {prepareMarkets()}
          </IonList>
          : <LoaderContent />
        }
      </IonContent>
    </IonPage >
  )
}

export default connectMyMarkets()(MyMarkets)
