import React, { useRef, useState } from 'react'
import '../promotion.scss'
import QuickButton from './QuickButton/QuickButton'
import { useDispatch, useSelector } from 'react-redux';
import { setIsNavigationID, setIsNavigationName } from '../../../store/navigationHeaderMenu/navigationHeaderMenu.slice';
import { setLoginNew } from '../../../store/LoginNew/loginNew.slice';
import { openSlotCasinoPopUp } from '../../../store/slotCasinoPopUp/slotCasinoPopUp.slice';
import { useHistory } from 'react-router';
import { setLoginNavigationData } from '../../../store/loginNavigation/LoginNavigation.slice';

const QuickButtons = ({ quickButtons }) => {
    const quickButtonsSortForIndex = [...quickButtons].sort((a, b) => a.index > b.index ? 1 : -1);
    const slider = useRef(null);
    const [isDown, setIsDown] = useState(false);
    const [isSlide, setIsSlide] = useState(false);
    const [startX, setStartX] = useState("");
    const [scrollLeft, setScrollLeft] = useState("");
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const history = useHistory();


    const quickButtonsList = quickButtonsSortForIndex.map((itemButton, index) => {
        
        const handleClickEvent = () => {
            if(!user?.verifytoken) {
                dispatch(setLoginNew(true));
                dispatch(setLoginNavigationData((itemButton?.name === 'casino-lobby')? 
                    {page: 'livecasino-lobby', ids: []} :
                    (itemButton?.name === 'Casino')? {navigationId: '99999', navigationName: 'Casino'} :
                    itemButton?.name?.includes('Game')? {page: 'casino_live', ids: [itemButton?.gameId, itemButton?.tableId]} : {}
                ));
            }else{
                if(itemButton?.name === 'casino-lobby') {
                    history.push('/livecasino-lobby');
                    dispatch(openSlotCasinoPopUp());
                }else if(itemButton?.name === 'Casino') {
                    dispatch(setIsNavigationID('99999'))
                    dispatch(setIsNavigationName('Casino'));
                }else if(itemButton?.name?.includes('Game')) {
                    history.push(`/casino_live/${itemButton?.gameId}/${itemButton?.tableId}`);
                    dispatch(openSlotCasinoPopUp());
                }
            }
        }

        return (
            <QuickButton
                isSlide={isSlide}
                key={index}
                name={itemButton.name}
                icon={itemButton.icon}
                redirect={ itemButton.redirect}
                backgroundImage={itemButton.backgroundImage}
                backgroundColour={itemButton.backgroundColour}
                id={itemButton.id}
                onSelect={() => {handleClickEvent()}}
            >
            </QuickButton>
        )
    })


    let quickButtonsStyle
    if (quickButtons.length == 1) {
        quickButtonsStyle = {gridTemplateColumns: '1fr'}
    }
    return (
        <div 
            className={'promotion-buttons'}
            ref={slider}
            style={quickButtonsStyle}
            onMouseDown={(e) => {
               setIsDown(true)
               setStartX(e.pageX - slider.current.offsetLeft)
               setScrollLeft(slider.current.scrollLeft)
            }}
            onMouseUp={() => {
                if (isSlide) {
                    setTimeout(() =>    setIsSlide(false), 500)
                }
                setIsDown(false)
            }}
            onMouseMove={(e) => {
                if(!isDown) return;
                e.preventDefault();
                setIsSlide(true)
                const x = e.pageX - slider.current.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.current.scrollLeft = scrollLeft - walk;
            }}
            onMouseLeave={() => {
                setIsDown(false)
                setIsSlide(false)
            }}
        >
            {quickButtonsList}
        </div>
    )
}

export default QuickButtons
