import NewHttpAuth from '../../services/NewHttpAuth'
import { getHomeAllCompetitionSuccess, getHomeAllCompetitionError, getHomeAllEventsSuccess, getHomeAllEventsError } from './homeMarketEvents.slice'


export const requestHomeAllCompetition = (data) => async (dispatch, getState) => {
    const auth = getState().auth
    if(auth) {
        try {
            const response = await NewHttpAuth.post('getHomeAllCompitition', data);
            if(response.status === 200 && response?.data?.success) {
                dispatch(getHomeAllCompetitionSuccess(response?.data?.response));
            }

            return response
        } catch (e) {
            dispatch(getHomeAllCompetitionError())
        }
    } else {
        try {
            const response = await NewHttpAuth.post(`getFreeHomeAllCompitition`, data);
            if(response.status === 200 && response?.data?.success) {
                dispatch(getHomeAllCompetitionSuccess(response?.data?.response));
            }

            return response;
        } catch (e) {
            dispatch(getHomeAllCompetitionError())
        }
    }
    
}


export const requestHomeAllEvents = (data) => async (dispatch, getState) => {
    const auth = getState().auth
    if(auth) {
        try {
            const response = await NewHttpAuth.post('getHomeAllEvents', data);
            if(response.status === 200 && response?.data?.success) {
                setTimeout(() => {
                    dispatch(getHomeAllEventsSuccess(response?.data?.response));
                }, 500)
            }

            return response
        } catch (e) {
            dispatch(getHomeAllEventsError())
        }
    } else {
        try {
            const response = await NewHttpAuth.post(`getFreeHomeAllEvents`, data);
            if(response.status === 200 && response?.data?.success) {
                setTimeout(() => {
                    dispatch(getHomeAllEventsSuccess(response?.data?.response));
                }, 500)
            }

            return response;
        } catch (e) {
            dispatch(getHomeAllEventsError())
        }
    }
    
}

