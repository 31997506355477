import httpAuth from '../../services/httpAuth'
import jwt from 'jsonwebtoken'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
  setMarket,
  setMarketType,
  setRunner,
  setIsBack,
  setPrice,
  setDelay,
  setStake,
  setMaxBet,
  setMaxWinPerMarket,
  setMtype,
  setMinBet,
  setEventTypeId,
  setEventId,
  setCompetitionId,
  setPriceActual,
  setMinMaxAll,
  setIsConfirmBetPopUpOpen,
  setBetDelay,
  setMarketName,
  setRunnerName,
  setEventName,
  setIsCashOut,
  setStartTime

} from './betPlacing.slice'
import { getAuthSuccess } from '../auth/auth.slice'
import { setAuthDataSuccess } from '../auth/authData.slice'
import { ID_TOKEN, TOKEN_EXP } from '../../config/app.config'
import { setScrollPosition } from '../elementsParams/elementsParams.slice'
import NewHttpAuth from '../../services/NewHttpAuth'
import { profitCalculate } from '../../helpers/profitCalculate'


export const requestBetDelay = () => async (dispatch, getState) => {
  const auth = getState().auth;
  
  const staticBetDelay = {
    matchoddsBetDelay: 5,
    soccerBetDelay: 5,
    tennisBetDelay: 5,
    bookmakerBetDelay: 5,
    fancyBetDelay: 5,
    ballbyballBetDelay: 5,
    virtualBetDelay: 5,
  };

  const staticMinMax = {
    fancyMinLimit: "1",
    fancyMaxLimit: "100000",
    oddsMinLimit: "1",
    oddsMaxLimit: "100000",
    bookmakerMinLimit: "100",
    bookmakerMaxLimit: "2000000",
    sfancyMinLimit: "100",
    sfancyMaxLimit: "50000",
    soddsMinLimit: "100",
    soddsMaxLimit: "50000",
    sbookmakerMinLimit: "100",
    sbookmakerMaxLimit: "50000",
    tfancyMinLimit: "100",
    tfancyMaxLimit: "50000",
    toddsMinLimit: "100",
    toddsMaxLimit: "50000",
    tbookmakerMinLimit: "100",
    tbookmakerMaxLimit: "50000",
  }


  if(auth) {
    try {
      const response = await NewHttpAuth.post('getUserSetting', {
        headers: {
          authorization: `Bearer ${auth}`
        }
      });

      if(response.status == 200 && response?.data?.success) {
        dispatch(setBetDelay({
          matchoddsBetDelay: response?.data?.data?.oddsBetDelay,
          soccerBetDelay: response?.data?.data?.soccerBetDelay,
          tennisBetDelay: response?.data?.data?.tennisBetDelay,
          bookmakerBetDelay: response?.data?.data?.bookmakerBetDelay,
          fancyBetDelay: response?.data?.data?.fancyBetDelay,
          ballbyballBetDelay: response?.data?.data?.ballbyballBetDelay,
          virtualBetDelay: response?.data?.data?.virtualBetDelay,
        }));

        dispatch(setMinMaxAll({
          fancyMinLimit: response?.data?.data?.fancyMinLimit,
          fancyMaxLimit: response?.data?.data?.fancyMaxLimit,
          oddsMinLimit: response?.data?.data?.oddsMinLimit,
          oddsMaxLimit: response?.data?.data?.oddsMaxLimit,
          bookmakerMinLimit: response?.data?.data?.bookmakerMinLimit,
          bookmakerMaxLimit: response?.data?.data?.bookmakerMaxLimit,
          sfancyMinLimit: response?.data?.data?.sfancyMinLimit,
          sfancyMaxLimit: response?.data?.data?.sfancyMaxLimit,
          soddsMinLimit: response?.data?.data?.soddsMinLimit,
          soddsMaxLimit: response?.data?.data?.soddsMaxLimit,
          sbookmakerMinLimit: response?.data?.data?.sbookmakerMinLimit,
          sbookmakerMaxLimit: response?.data?.data?.sbookmakerMaxLimit,
          tfancyMinLimit: response?.data?.data?.tfancyMinLimit,
          tfancyMaxLimit: response?.data?.data?.tfancyMaxLimit,
          toddsMinLimit: response?.data?.data?.toddsMinLimit,
          toddsMaxLimit: response?.data?.data?.toddsMaxLimit,
          tbookmakerMinLimit: response?.data?.data?.tbookmakerMinLimit,
          tbookmakerMaxLimit: response?.data?.data?.tbookmakerMaxLimit,
        }));
      }else {
        dispatch(setBetDelay(staticBetDelay));
        dispatch(setMinMaxAll(staticMinMax));
      }
    } catch (e) {
      dispatch(setBetDelay(staticBetDelay));
      dispatch(setMinMaxAll(staticMinMax));
    }
  } else {
    dispatch(setBetDelay(staticBetDelay));
    dispatch(setMinMaxAll(staticMinMax));
  }
}


export const requestBetPlacingStart = (marketId, runnerId, setAll = false ) => async (dispatch, getState) => {
  const auth = getState().auth;
  if(auth) {
    try {
      const response = await httpAuth.post('/exchange/order/settings/',  { marketId, runnerId })
      // if ( setAll && response.data.result) {
      //   console.log("setAll")
      //   dispatch(setMinMaxAll({
      //     maxBet: response.data.result.maxBet,
      //     maxWinPerMarket: response.data.result.maxWinPerMarket,
      //     marketId: response.data.result.marketId
      //   }))
      //   return
      // }
      if (setAll) {return response}
      if (response.data.result) {
        dispatch(setMaxBet(response.data.result.maxBet))
        dispatch(setMaxWinPerMarket(response.data.result.maxWinPerMarket))
        dispatch(setMtype(response.data.result.mType))
        dispatch(setMinBet(response.data.result.minBet))
        dispatch(setEventTypeId(response.data.result.eventTypeId))
        dispatch(setEventId(response.data.result.eventId))
        dispatch(setCompetitionId(response.data.result.competitionId))
      }

    } catch (e) {
      dispatch(setMarket(null))
      dispatch(setRunner(null))
      dispatch(setIsBack(null))
      dispatch(setPrice(null))
      dispatch(setPriceActual(null))
      dispatch(setDelay(null))
      dispatch(setStake(null))
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg;
    }
  } else {
    console.log("not auth so betSettings not called")
  }
}
// export const requestBetPlacingStartAll = (markets) => async (dispatch) => {
//       try {
//         const res = await Promise.allSettled(markets.map(item =>  dispatch(requestBetPlacingStart(item.id,  item.runners[0].id, true))));
//         console.log("res", res)
//         }
//         catch (e) {
//           console.log("ERRRRR", e)
//       }
// }

export const requestSetOrder = (betPlacing) => async (dispatch, getState) => {
  try {
    const response = await httpAuth.post('/exchange/order/', [
      {
        side: betPlacing.isBack ? 0 : 1,
        price: betPlacing.priceActual,
        selectionId: betPlacing.runner,
        btype: betPlacing.btype,
        oddsType: betPlacing.oddsType,
        eventTypeId: betPlacing.eventTypeId,
        marketId: betPlacing.market,
        totalSize: Number(betPlacing.stake),
        betSlipRef: betPlacing.betSlipRef,
        fromOneClick: betPlacing.fromOneClick ? 1 : 0,
        betDelay: betPlacing.delay,
        line: betPlacing.line,
      }
    ],
      {
        headers: {
          'x-user-id': getState().user.memberCode,
          // temporary hard-coded
          'x-app-version': '4.0.19.2',
          'x-client': 'mobile',
          'x-client-id': '451012928.1605029998',
          'x-client-info': 'e883e9a15db99de2fdd0579c576a0693',
          'x-log-timing': true,
          'x-xid': '6b76ff17-70d2-b5fe-2373-08f62ee312af',
        }
      }
    )
    if (response.headers['authorization']) {
      const decoded = jwt.decode(response.headers['authorization'], { complete: true })
      dispatch(getAuthSuccess(response.headers['authorization']))

      dispatch(setAuthDataSuccess(decoded))
      localStorage.setItem(ID_TOKEN, response.headers['authorization'])
      localStorage.setItem(TOKEN_EXP, decoded.payload.exp)
    }
    dispatch(setMarket(null))
    dispatch(setRunner(null))
    dispatch(setPrice(null))
    dispatch(setPriceActual(null))
    dispatch(setDelay(null))
    dispatch(setStake(null))
    return response
  } catch (e) {
    dispatch(setMarket(null))
    dispatch(setRunner(null))
    dispatch(setPrice(null))
    dispatch(setPriceActual(null))
    dispatch(setDelay(null))
    dispatch(setStake(null))
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestCashOut = (market) => async (dispatch, getState) => {
  try{
    const auth = getState()?.auth
    const eventOpenBets = getState()?.openBets?.eventPageOpenBets;
    
    if(!market || !auth || !eventOpenBets || !eventOpenBets?.length) return;


    const requiredBets = eventOpenBets.filter(item => item?.marketId == market?.marketId && item?.marketName == market?.marketName);
    let greenAmount = 0;
    let redAmount = 0;
    let currentlaygrrenodds = 0;
    let runners;
    let runners_point = [];

    for (let i = 0; i < market?.marketBook?.runners?.length; i++) {
      const number = profitCalculate(requiredBets, 
        market?.marketBook?.runners[i]?.selectionId,
        market?.marketId
      );
      if (number > 0) {
        greenAmount = number;
        currentlaygrrenodds = market?.marketBook.runners[i]?.availableToLay?.price;
        runners = market?.marketBook?.runners[i];
        runners_point.push(greenAmount)
      }else{
        redAmount = number;
        runners_point.push(redAmount);
      }
    }
    let layStake = (greenAmount - redAmount) / currentlaygrrenodds;
    if (!runners) {
      if (Math.trunc(runners_point[0]) == Math.trunc(runners_point[1])) {
        return;
      }

      if (runners_point[0] > runners_point[1]) {
        runners = market?.marketBook?.runners[0];
        greenAmount = profitCalculate(requiredBets, market?.marketBook?.runners[0]?.selectionId, market?.marketId);
        currentlaygrrenodds = market?.marketBook?.runners[0]?.availableToLay?.price;
        redAmount = profitCalculate(requiredBets, market?.marketBook?.runners[1]?.selectionId, market?.marketId);
      }
      else {
        runners = market.marketBook.runners[1];
        greenAmount = profitCalculate(requiredBets, market?.marketBook?.runners[1]?.selectionId, market?.marketId);;
        currentlaygrrenodds = market.marketBook.runners[1]?.availableToLay?.price;
        redAmount = profitCalculate(requiredBets, market?.marketBook?.runners[0]?.selectionId, market?.marketId);
      }
    }
    if (layStake === Infinity) {
      layStake = (greenAmount - redAmount) / currentlaygrrenodds;
    }


    dispatch(setStake(layStake));
    dispatch(setMarket(market&& market?.marketId));
    dispatch(setMarketType(market&& market?.marketType));
    dispatch(setMarketName(market && market?.marketName));
    dispatch(setRunner(runners&& runners?.selectionId));
    dispatch(setRunnerName(runners&& runners?.runnerName));
    dispatch(setEventName(market && market?.eventName));
    dispatch(setEventId(market && market?.eventId));
    dispatch(setIsBack(false));
    dispatch(setPrice(runners&& runners?.availableToLay?.price));
    dispatch(setPriceActual(runners&& runners?.availableToLay?.price));
    dispatch(setStartTime(market && market?.openDate));
    dispatch(setEventTypeId(market && market?.eventTypeId));
    dispatch(setIsCashOut(true));
  }catch(err) {
    console.log("Error: ", err);
  }
  
  
  // try {
  //   const response = await httpAuth.get(`/exchange/order/cashout?marketId=${marketId}`,{
  //     headers: {
  //       'x-app-version': '4.0.19.2',
  //       'x-client': 'mobile',
  //       'x-client-id': '451012928.1605029998',
  //       'x-client-info': 'e883e9a15db99de2fdd0579c576a0693',
  //       'x-log-timing': true,
  //       'x-xid': '6b76ff17-70d2-b5fe-2373-08f62ee312af',
  //     }
  //   })
  //   if (response.data.success) {
  //     // dispatch(getSportLadderSuccess(response.data.result))
  //     if (response.headers['authorization']) {
  //       const decoded = jwt.decode(response.headers['authorization'], { complete: true })
  //       dispatch(getAuthSuccess(response.headers['authorization']))

  //       dispatch(setAuthDataSuccess(decoded))
  //       localStorage.setItem(ID_TOKEN, response.headers['authorization'])
  //       localStorage.setItem(TOKEN_EXP, decoded.payload.exp)
  //     }
  //     console.log(response.data.result);
  //   }
  //   return response
  // } catch (e) {
  //   const errorMsg = getErrorMessage(e)
  //   throw errorMsg
  //   // dispatch(getSportLadderError())
  // }
}

export const requestCancelOrder = (orderId) => async (dispatch) => {
  try {
    const response = await httpAuth.delete(`/exchange/order/${orderId}`)
    if (response.data.success) return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestCancelAllBets = () => async (dispatch) => {
  try {
    const response = await httpAuth.delete(`/exchange/order`)
    if (response.data.success) return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

//
export const isConfirmBetPopUpOpenThunk = (open) => async (dispatch) => {
  if (open) {
    dispatch(setIsConfirmBetPopUpOpen(open))
  }
  if (!open) {
    dispatch(setIsConfirmBetPopUpOpen(false))
  }
}
//
