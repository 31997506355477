import CricketImg from '../assets/images/promotions/sportsButton/cricicon.svg';
import CricketBG from '../assets/images/promotions/sportsButton/cricket-bg.png';
import FootballImg from '../assets/images/promotions/sportsButton/footballicon.svg';
import FootballBg from '../assets/images/promotions/sportsButton/football-bg.png';
import TennisImg from '../assets/images/promotions/sportsButton/tennisicon.svg';
import TennisBg from '../assets/images/promotions/sportsButton/tennis-bg.png';
import VirtualBg from '../assets/images/promotions/sportsButton/virtual-bg.png';
import CasinoImg from '../assets/images/promotions/sportsButton/casinoImg.svg';
import CasinoBg from '../assets/images/promotions/sportsButton/casino-bg.png';

import QuickBtnPoster1 from '../assets/images/promotions/quickButtons/casino_lobby.webp';
import QuickBtnPoster2 from '../assets/images/promotions/quickButtons/jetx-730_280.webp';
import QuickBtnPoster3 from '../assets/images/promotions/quickButtons/limbo-730-280-blue.gif';
import QuickBtnPoster4 from '../assets/images/promotions/quickButtons/mac88-730_280.webp';
import QuickBtnPoster5 from '../assets/images/promotions/quickButtons/pilot-730_280.webp';
import QuickBtnPoster6 from '../assets/images/promotions/quickButtons/sportsbook.webp';

import Banner1 from '../assets/images/promotions/banners/banner-1-lotus.webp';
import Banner3 from '../assets/images/promotions/banners/banner-3-lotus.webp';
import Banner4 from '../assets/images/promotions/banners/banner-4-lotus.webp';
import Banner5 from '../assets/images/promotions/banners/banner-5-lotus.webp';
import Banner6 from '../assets/images/promotions/banners/banner-6-lotus.webp';


export const sportsButtonData = [
    {
        name: 'Cricket',
        icon: CricketImg,
        eventTypeId: '4',
        backgroundImage: CricketBG,
        backgroundColour: 'initial',
        id: '4',
        index: 1,
    },
    {
        name: 'Football',
        icon: FootballImg,
        eventTypeId: '1',
        backgroundImage: FootballBg,
        backgroundColour: 'initial',
        id: '1',
        index: 2,
    },
    {
        name: 'Tennis',
        icon: TennisImg,
        eventTypeId: '2',
        backgroundImage: TennisBg,
        backgroundColour: 'initial',
        id: '2',
        index: 3,
    },
    // {
    //     name: 'Virtual Cricket',
    //     icon: CricketImg,
    //     eventTypeId: '4',
    //     backgroundImage: VirtualBg,
    //     backgroundColour: 'initial',
    //     id: '4',
    //     index: 4,
    // },
    {
        name: 'Casino',
        icon: CasinoImg,
        eventTypeId: 'c88',
        backgroundImage: CasinoBg,
        backgroundColour: 'initial',
        id: 'c9',
        index: 5,
    },
];


export const quickButtonData = [
    {
        name: 'casino-lobby', 
        icon: '', 
        redirect: '', 
        backgroundImage: QuickBtnPoster1, 
        backgroundColour: 'initial', 
        id: 1,
        index: 1,
    },
    {
        name: 'Game - Crash', 
        icon: '', 
        redirect: '', 
        backgroundImage: 'https://client.qtlauncher.com/images/?id=TRB-crashx_en_US&type=banner&version=1689793159588', 
        backgroundColour: 'initial', 
        id: 2,
        index: 2,
        gameId: 'TRB-crashx',
        tableId: '',
    },
    {
        name: 'Game - Limbo', 
        icon: '', 
        redirect: '', 
        backgroundImage: QuickBtnPoster3, 
        backgroundColour: 'initial', 
        id: 3,
        index: 3,
        gameId: 'GLX-limbocrash',
        tableId: '',
    },
    {
        name: 'Casino', 
        icon: '', 
        redirect: '', 
        backgroundImage: QuickBtnPoster4, 
        backgroundColour: 'initial', 
        id: 4,
        index: 4,
    },
    {
        name: 'Game - Aviator', 
        icon: '', 
        redirect: '', 
        backgroundImage: 'https://client.qtlauncher.com/images/?id=SPB-aviator_en_US&type=banner&version=1717639255542', 
        backgroundColour: 'initial', 
        id: 5,
        index: 5,
        gameId: 'SPB-aviator',
        tableId: '',
    },
    {
        name: '', 
        icon: '', 
        redirect: '', 
        backgroundImage: QuickBtnPoster6, 
        backgroundColour: 'initial', 
        id: 6,
        index: 6,
    },
];

export const promotionBanner = [
    {
        _id: 'banner_1',
        name: '',
        description: '',
        image: Banner1,
        deleted: false,
        visible: true,
    },
    {
        _id: 'banner_3',
        name: '',
        description: '',
        image: Banner3,
        deleted: false,
        visible: true,
    },
    {
        _id: 'banner_4',
        name: '',
        description: '',
        image: Banner4,
        deleted: false,
        visible: true,
    },
    {
        _id: 'banner_5',
        name: '',
        description: '',
        image: Banner5,
        deleted: false,
        visible: true,
    },
    {
        _id: 'banner_6',
        name: '',
        description: '',
        image: Banner6,
        deleted: false,
        visible: true,
    },
];