export const VirtualData = {
    eventTypeId: '4',
    eventName: '🎮 Virtual',
    eventId: '1234822733',
    actualEventTypeId: 'v9',
    marketType: 'virtual',
    competitionName: '🎮 - Virtual',
    runners: [
        {
            "selectionId": 'v9_1',
            "runnerName": 'v9__1',
            "handicap": '',
            "sortPriority": 1
        },
        {
            "selectionId": 'v9_2',
            "runnerName": "v9__2",
            "handicap": '',
            "sortPriority": 2
        }
    ],
    marketBook: {
        "status": "OPEN",
        "inplay": true,
        "runners": [
            {
                "selectionId": 'v9_1',
                "status": "ACTIVE",
                "runnerName": "v9__1",
                "availableToBack": {
                    "price": "",
                    "size": 0
                },
                "availableToLay": {
                    "price": "1.99",
                    "size": 0
                }
            },
            {
                "selectionId": 'v9_2',
                "status": "ACTIVE",
                "runnerName": "v9__2",
                "availableToBack": {
                    "price": "",
                    "size": 0
                },
                "availableToLay": {
                    "price": "",
                    "size": 0
                }
            }
        ]
    },
}

export const BallByBallData = {
    eventTypeId: '4',
    eventName: '🎮 Ball By Ball',
    actualEventTypeId: 'b9',
    marketType: 'virtual',
    eventId: '1122334455',
    competitionName: '🎮 - Ball By Ball',
    runners: [
        {
            "selectionId": 'b9_1',
            "runnerName": 'b9__1',
            "handicap": 0,
            "sortPriority": 1
        },
        {
            "selectionId": 'b9_2',
            "runnerName": "b9__2",
            "handicap": 0,
            "sortPriority": 2
        }
    ],
    marketBook: {
        "status": "OPEN",
        "inplay": true,
        "runners": [
            {
                "selectionId": 'b9_1',
                "status": "ACTIVE",
                "runnerName": "b9__1",
                "availableToBack": {
                    "price": "0",
                    "size": 0
                },
                "availableToLay": {
                    "price": "1.99",
                    "size": 0
                }
            },
            {
                "selectionId": 'b9_2',
                "status": "ACTIVE",
                "runnerName": "b9__2",
                "availableToBack": {
                    "price": "0",
                    "size": 0
                },
                "availableToLay": {
                    "price": "0",
                    "size": 0
                }
            }
        ]
    },
}