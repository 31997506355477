import React, { useMemo, Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import leftSideMenuMapper from '../../../helpers/getLeftSideMenuMapper.helper';
import CasinoGridList from '../../CasinoGridList/CasinoGridList';
import ExchangeGamesGridList from '../../ExchangeGamesGridList/ExchangeGamesGridList';
import InPlayPopularItemList from '../../InPlayItemList/inPlayPopularItemList';
import PopularGamesGridList from '../../PopularGamesGridList/PopularGamesGridList';

const GetListsItems = ({inPlayEventsMap, type, timeSettings, betPlacing, displayedBack, setDisplayedBack, balanceSettings, navigationID}) => {
    const history = useHistory();
  
    const filteredList = useMemo(() => {
        return Object.entries(leftSideMenuMapper)
        .filter(([key, value]) => {
            return !!inPlayEventsMap.get(value.id) && 
                ((navigationID === '' && value.id == '4') || value.id == navigationID);
        });
    }, [leftSideMenuMapper, inPlayEventsMap, navigationID]);

    return useMemo(() => {
        return filteredList.map(([key, value], index) => {
        switch (value.id) {
            case 77777:
                return (
                    history.location.pathname !== '/favouritesPage' && (
                    <div key={`${value.id}_${type}`}>
                        <CasinoGridList
                            key={value.id}
                            type={type}
                            itemHeaderData={value}
                            itemsData={inPlayEventsMap.get(value.id)}
                        />
                    </div>
                    )
                );

            case 1444001:
                return history.location.pathname !== '/favouritesPage' ? (
                    <ExchangeGamesGridList
                        key={`${value.id}_${type}`}
                        type={type}
                        itemHeaderData={value}
                        itemsData={inPlayEventsMap.get(value.id)}
                    />
                ) : (
                    <InPlayPopularItemList
                        type={type}
                        timeSettings={timeSettings}
                        betPlacing={betPlacing}
                        key={`${value.id}_${type}`}
                        displayedBack={displayedBack}
                        setDisplayedBack={setDisplayedBack}
                        itemHeaderData={value}
                        balanceSetting={balanceSettings && balanceSettings[value.id]}
                        itemsData={inPlayEventsMap.get(value.id).sort(a => a?.event?.name?.includes("SRL") ? 1 : -1).sort(a => a?.market?.id?.includes("BR") ? 1 : -1)}
                    />
                );
            case 1111111:
                return history.location.pathname !== '/favouritesPage' ? (
                    <PopularGamesGridList
                        key={`${value.id}_${type}`}
                        type={type}
                        itemHeaderData={value}
                        itemsData={inPlayEventsMap.get(value.id)}
                    />
                ) : (
                    <InPlayPopularItemList
                        type={type}
                        timeSettings={timeSettings}
                        betPlacing={betPlacing}
                        key={`${value.id}_${type}`}
                        displayedBack={displayedBack}
                        setDisplayedBack={setDisplayedBack}
                        itemHeaderData={value}
                        balanceSetting={balanceSettings && balanceSettings[value.id]}
                        itemsData={inPlayEventsMap.get(value.id).sort(a => a?.event?.name?.includes("SRL") ? 1 : -1).sort(a => a?.market?.id?.includes("BR") ? 1 : -1)}
                    />
                );

            default:
                return (
                    <Suspense fallback={<div>Loading...</div>} key={`${value.id}_${type}`}>
                        <InPlayPopularItemList
                            type={type}
                            timeSettings={timeSettings}
                            betPlacing={betPlacing}
                            key={`${value.id}_${type}`}
                            displayedBack={displayedBack}
                            setDisplayedBack={setDisplayedBack}
                            itemHeaderData={value}
                            balanceSetting={balanceSettings && balanceSettings[value.id]}
                            itemsData={inPlayEventsMap.get(value.id).sort(a => a?.event?.name?.includes("SRL") ? 1 : -1).sort(a => a?.market?.id?.includes("BR") ? 1 : -1)}
                        />
                    </Suspense>
                );
            }
        });
    }, [filteredList, history.location.pathname, inPlayEventsMap, type, timeSettings, betPlacing, displayedBack, setDisplayedBack, balanceSettings]);
};

export default GetListsItems;
