import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getBalanceError, getBalanceSuccess } from './balance.slice'
import { requestLogout } from '../auth/auth.thunks'
import {toastr} from "react-redux-toastr";


export const requestBalance = (memberCode) => async (dispatch) => {
  try {
    const response = await httpAuth.post(`getUserDetails`)
    if (response.data.success) {
      dispatch(getBalanceSuccess(response.data.doc))
    }else{
      // if(response.data?.logout) {
      //   toastr.error('', response.data?.message || 'Please Login Again');
      //   setTimeout(() => {
      //     console.log("logout");
      //     dispatch(requestLogout()); 
      //   }, 2000)
      // }
    }
    return response
  } catch (e) {
    dispatch(getBalanceError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}

export const changeWalletStatus = (memberCode, bool, walletCode) => async (dispatch) => {
  try {
    const response = await httpAuth.put(`/account/${memberCode}/settings/switch-wallet?useBonusWallet=${bool}&walletCode=${walletCode}`)
    if (response.data.success) {
      console.log(response.data)
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}
