export const CasinoProviderDATA = [
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://betstarters.com/wp-content/uploads/2021/11/1x2.jpeg',
        name: '1X2 Gaming',
        provider: '1x2'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.7mojos.com/uploads/media/cache/live_game_tumbnail/images/7mojos-thumb-dragon-tiger-6567204f3cdec.png',
        name: '7Mojos Casino', 
        provider: '7MC'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://slotcatalog.com/userfiles/image/brand/7mojos_m.jpg',
        name: '7Mojos Live', 
        provider: '7ML'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://i0.wp.com/europeangaming.eu/portal/wp-content/uploads/2023/02/ad-lunam.png?resize=1000%2C600&ssl=1',
        name: 'Ad Lunam', 
        provider: 'ADL'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://avatarux.com/wp-content/uploads/2021/06/AUX%E2%80%94OG-image-1.jpg',
        name: 'AvatarUX', 
        provider: 'AUX'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://a1.lcb.org/system/review_images/attachments/000/440/135/converted/beter-software-review.jpg',
        name: 'BETER Live', 
        provider: 'BTL'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHiPbZ5xYu-m8NW4vM8_BgOtCnqG3O2Rqj2g&s',
        name: 'BetGames.TV', 
        provider: 'BTV'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiXJ3JvwGXl3abMChcTlZIlB25pqNGBmGX7w&s',
        name: 'Big Time Gaming', 
        provider: 'BTG'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://casinoalpha.com/wp-content/uploads/2024/07/Discover-the-8-Best-Blueprint-Gaming-Slots-You-Cant-Miss-Adela-Mariuta-.jpg',
        name: 'Blueprint Gaming', 
        provider: 'BPG'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.casinoreviews.net/assets/images/providers/booongo/booongo-company-information.jpg',
        name: 'Booongo', 
        provider: 'BNG'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://a1.lcb.org/system/modules/news_story/images/attachments/000/305/166/amp/Vegas-Crest-is-launching-cQ9.jpg',
        name: 'CQ9 Casino', 
        provider: 'CQC'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://assets.vegasslotsonline.com/vegasslotsonline.com/assets/uploads/software/big/dragoon-soft.png',
        name: 'Dragoon Soft', 
        provider: 'DS'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC-bLDuoWde6dAZYREyOB5DhjxFo8T6rcpgw&s',
        name: 'Elk Studios', 
        provider: 'ELK'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcU3RzFFZ-wM3qx6t0GOIhpKcXtU0T8Q__Tw&s',
        name: 'Endorphina', 
        provider: 'END'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://games.evolution.com/wp-content/uploads/2023/07/social-thumbnail.jpg',
        name: 'Evolution Gaming', 
        provider: 'EVO'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMM_tOCZ38iN2FeKtcoGzzkb6kbxPmT0Z56w&s',
        name: 'Evoplay', 
        provider: 'EVP'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://bitcoinchaser.com/wp-content/uploads/2020/07/ezugi-games__800x480-compressor.png',
        name: 'Ezugi', 
        provider: 'EZU'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://images.sigma.world/fa-chai-logo.jpg',
        name: 'Fa Chai', 
        provider: 'FCG'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://assets.gamingintelligence.com/wp-content/uploads/2020/05/29090254/Fantasma_Games-1024x576.jpg',
        name: 'Fantasma Games', 
        provider: 'FNG' 
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.bonusinsider.com/wp-content/uploads/2022/11/fugaso-intro.webp',
        name: 'Fugaso', 
        provider: 'FUG' 
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://5star.media/wp-content/uploads/2023/01/quantum-x-galaxsys.png',
        name: 'Galaxsys', 
        provider: 'GLX'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://i.ytimg.com/vi/uBNv44rhJi8/maxresdefault.jpg',
        name: 'GAMEART', 
        provider: 'GA'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.gamblinginsider.com/img/news/gaming-corps.jpg',
        name: 'Gaming Corps', 
        provider: 'GMC'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRl3d7xSfDaA0nQ0w2KLtNMd8y-JdRz2CjBxA&s',
        name: 'Gamzix', 
        provider: 'GZX'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://habanerosystems.com/assets/img/share.jpg',
        name: 'Habanero', 
        provider: 'HAB'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.playwv.com/wp-content/uploads/2022/11/Hacksaw-Gaming-Makes-First-US-Stop-In-West-Virginia.jpg',
        name: 'Hacksaw Gaming', 
        provider: 'HAK'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.gamingsoft.com/Content/v2/images/new-provider/the-online-casino-product-from-ho-gaming-gamingsoft.png',
        name: 'Ho Gaming', 
        provider: 'HOG'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.casinoreviews.net/assets/images/providers/iron-dog-studio/iron-dog-studio-info.jpg',
        name: 'Iron Dog Studio', 
        provider: 'IDS'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.casinoreviews.net/assets/images/providers/kalamba-games/kalamba-company-information.jpg',
        name: 'Kalamba Games', 
        provider: 'KGL'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://i.vimeocdn.com/video/1291206646-c0660fb60052274cd648a486328754b6976af90d43fc46ad7',
        name: 'Kiron Interactive', 
        provider: 'KIR'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.insidecasino.ca/wp-content/uploads/2023/10/Manna-Play-casinos.png',
        name: 'Manna Play', 
        provider: 'MNP'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://a2.lcb.org/system/review_images/attachments/000/304/473/converted/mobilots.jpg',
        name: 'Mobilots', 
        provider: 'MOB'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://2wpower.com/public/userfiles/gamessystems/2017-09/Netent-slots.jpg',
        name: 'NetEnt', 
        provider: 'NE'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.jeu-legal-france.fr/bannersnews/netgame-entertainment-logo.jpg',
        name: 'NetGame Entertainment', 
        provider: 'NGE'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.casinoreviews.net/assets/images/providers/nolimit-city/more-about-the-company-nolimit.jpg',
        name: 'Nolimit city', 
        provider: 'NLC'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://5star.media/wp-content/uploads/2017/01/OMI.png',
        name: 'OMI Gaming', 
        provider: 'OMI'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://5star.media/wp-content/uploads/2017/01/OMI.png',
        name: 'OMI Gaming', 
        provider: 'OMI'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://slotcatalog.com/userfiles/image/brand/OneTouch_m.jpg',
        name: 'OneTouch', 
        provider: 'OT'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://a1.lcb.org/system/review_images/attachments/000/410/535/converted/onlyplay-online-slots-slot-page.jpeg',
        name: 'Onlyplay', 
        provider: 'OPY'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://next.io/wp-content/uploads/2023/12/Playn-GO.jpg',
        name: 'PLAY’n GO', 
        provider: 'PNG'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.gamblingsites.com/app/uploads/2021/08/Playtech-Casinos-Logo.jpg',
        name: 'Playtech Casino', 
        provider: 'PTC'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://igaming.news/img/articles/1200X630-BlackJack-Footer-EN.png',
        name: 'Pragmatic Play Casino', 
        provider: 'PPC'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://igaming.news/img/articles/PRAGMATIC_PLAY_LAUNCHES_DICE_SLOT_GAMES_WITH_PEPPERMILL_CASINO_IN_BELGIUM_.png',
        name: 'Pragmatic Play Live', 
        provider: 'PPL'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://framerusercontent.com/images/KaQA8MZy5X04FD6m5bISdCK0Kh0.png',
        name: 'Print Studios', 
        provider: 'PRS'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/2216460/capsule_616x353.jpg?t=1705617264',
        name: 'Prospect Gaming', 
        provider: 'PRG' 
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.pushgaming.com/uploads/Images%20for%20OpenGraph/1_Website-Links_Home-Page_1200x630.jpg',
        name: 'Push Gaming', 
        provider: 'PUG'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.colossalslots.com/wp-content/uploads/2021/10/quickspin-logo.jpg',
        name: 'Quickspin', 
        provider: 'QS'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://theslotbuzz.com/wp-content/uploads/2018/10/Red_Tiger-lg.jpg',
        name: 'Red Tiger', 
        provider: 'RED'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://cf-cdn.relax-gaming.com/uploads/images/history-e732d170834d12f105b60eb88fe220c5.jpg',
        name: 'Relax Gaming', 
        provider: 'RLX'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://a1.lcb.org/system/modules/news_story/images/attachments/000/309/234/block/Pariplay-integrates-Revolver-Gaming-content-portfolio.jpg',
        name: 'Revolver Gaming', 
        provider: 'RG'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://focusgn.com/wp-content/uploads/2024/04/Rival-Powered-1200x630.jpeg',
        name: 'Rival Powered', 
        provider: 'RVP'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmYl2h6wORNeVAg528lK75zaRJWivGALOVTw&s',
        name: 'RTG Slots', 
        provider: 'RTG'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://sectordeljuego.com/wp-content/uploads/2024/04/s4gaming.jpg',
        name: 'S4 Gaming', 
        provider: 'S4G'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://politicahoy.com/wp-content/uploads/2024/03/sagaming-interview-cover.jpg',
        name: 'SA Gaming', 
        provider: 'SAG'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://cdn.skywindgroup.com/cdn/file/e9/6e/e96ec70a1d402bc2662b1a1d3ce8ea19d4055f37.jpg',
        name: 'Skywind Casino', 
        provider: 'SWC'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://cdn.skywindgroup.com/cdn/file/e9/6e/e96ec70a1d402bc2662b1a1d3ce8ea19d4055f37.jpg',
        name: 'Skywind Live', 
        provider: 'SWL'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://games.gamingintelligence.com/wp-content/uploads/2021/04/Slotmill.png',
        name: 'Slotmill', 
        provider: 'SM'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://a1.lcb.org/system/review_images/attachments/000/310/332/converted/spearhead_studios.jpg',
        name: 'Spearhead Studios', 
        provider: 'SHS'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://i3.wp.com/blog.mbitcasino.io/wp-content/uploads/2022/02/Spribe_Game-Mash-up_Blog-Cover.jpg?strip=all',
        name: 'Spribe', 
        provider: 'SPB'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://i.ytimg.com/vi/d5t5YcEmAO0/maxresdefault.jpg',
        name: 'Thunderkick', 
        provider: 'TK'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.livebet.com/images/casino/provider/tpg.webp',
        name: 'Triple PG', 
        provider: 'TPG'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://liongaming.io/wp-content/uploads/2024/04/TurboGames.png',
        name: 'Turbo Games', 
        provider: 'TRB'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://novusbet.com/s/game_provider/logo/vivogaming-22011142164191054224886.png',
        name: 'Vivo Gaming', 
        provider: 'VGL'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFNGFbsIOnWvqfpvDVf96DQUwLVtzTH6uahg&s',
        name: 'Wazdan', 
        provider: 'WAZ'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://news.worldcasinodirectory.com/wp-content/uploads/2020/05/woohoocover.jpg',
        name: 'Woohoo Games', 
        provider: 'WOO'
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://www.sevenjackpots.com/wp-content/uploads/2019/01/yggdrasil-logo-india-casino.png',
        name: 'Yggdrasil', 
        provider: 'YGG'
    },
];